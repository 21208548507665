import BigNumber from 'bignumber.js';
import { useRecoilValue } from 'recoil';
import { Fragment, useState, useCallback } from 'react';
import { Transition, Dialog } from '@headlessui/react';

import { ButtonOutlineMedim, ButtonPrimaryLarge } from '@/components/Button';
import Input from '@/components/Input';

import { balanceState, userInfoState, allowanceState } from '@/state/farms';

import { useFarms } from '@/hooks/useFarm';
import { MAX_TRANSACTION_CONFIRMATIONS } from '@/hooks/tools';

import { formatNumber4Loans } from '@/utils/format';
import { bigLessThanOrEqualTo } from '@/utils/operation';

export interface DialogProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  disabled: boolean | '';
  actionType: string;
  precision?: number;
  // actionHandler: (value: string) => void;
}

function ActionDialog(props: DialogProps) {
  const [inputValue, setOnchange] = useState('');
  const [loading, setLoading] = useState(false);

  const { approve, stake, unStake } = useFarms();
  const userInfos = useRecoilValue(userInfoState);
  const balance = useRecoilValue(balanceState);
  const allowance = useRecoilValue(allowanceState);

  const amount = formatNumber4Loans(userInfos.amount, { places: 8 });
  const isStake = props.actionType === 'Stake';
  const hasAllowance = allowance && new BigNumber(allowance).isGreaterThan(0);

  const closeModal = () => {
    props.setIsOpen(false);
    setOnchange('');
  };

  const suffix = (
    <ButtonOutlineMedim
      className="h-[1.88rem] w-max rounded-[0.13rem] border-[0.06rem] border-[#E921C3] pl-[1rem] pr-[1rem] text-[#E921C3]"
      onClick={() => {
        isStake ? setOnchange(balance) : setOnchange(amount);
      }}
      disabled={isStake ? !balance : !amount}
    >
      Max
    </ButtonOutlineMedim>
  );

  const btnDisabled = () => {
    let diabled = false;

    if (!hasAllowance) {
      diabled = false;
    } else {
      switch (props.actionType) {
        case 'Stake':
          if (!balance || !inputValue || props.disabled) {
            diabled = true;
          }

          break;
        case 'UnStake':
          if (!amount || !inputValue || props.disabled) {
            diabled = true;
          }
          break;
      }
    }

    return diabled;
  };

  const actionContent = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (type: any) => {
      try {
        setLoading(true);
        await type(inputValue);
        setLoading(false);
        setOnchange('');
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [inputValue]
  );

  const btnAction = useCallback(async () => {
    if (hasAllowance) {
      switch (props.actionType) {
        case 'Stake':
          actionContent(stake);
          break;
        case 'UnStake':
          actionContent(unStake);
          break;
        default:
          break;
      }
    } else {
      try {
        setLoading(true);
        const transaction = await approve('');
        if (transaction) {
          await transaction.wait(MAX_TRANSACTION_CONFIRMATIONS);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }, [actionContent, approve, hasAllowance, props.actionType, stake, unStake]);

  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 overflow-y-auto bg-[#0056D6]/20 backdrop-blur-sm backdrop-filter"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative z-30 my-8 inline-block min-h-[10.3rem] w-full max-w-[20rem] transform overflow-hidden rounded-lg border-[0.06rem] border-[#FFFFFF]/10 bg-[#131415] p-6 text-left align-middle shadow-xl transition-all md:max-w-[30rem]">
              <div
                className="absolute right-[0.8rem] top-[0.5rem] text-[1.3rem] font-normal leading-[1.3rem] text-[#fff] hover:cursor-pointer"
                onClick={() => {
                  props.setIsOpen(false);
                  setOnchange('');
                }}
              >
                x
              </div>
              <Dialog.Title as="h3" className="text-lg text-[1.38rem] font-medium leading-6 text-[#fff]">
                {props.actionType}
              </Dialog.Title>
              <div className="mt-[1.5rem]">
                <Input
                  inputClassName="bg-[#202122] border-0"
                  value={inputValue}
                  precision={props.precision || isStake ? 8 : 18}
                  onChange={(e) => {
                    setOnchange(e);
                  }}
                  placeholder="0.0"
                  suffix={suffix}
                  prefix="Amount"
                  disabled={
                    !hasAllowance || isStake ? bigLessThanOrEqualTo(balance, 0) : bigLessThanOrEqualTo(amount, 0)
                  }
                />
                <div className="mt-[1rem] flex w-max items-center text-[0.88rem] text-[#fff]/50 hover:cursor-pointer">
                  {isStake ? `Wallet Balance: ${balance} CLND/ATOM` : `Your staked: ${amount} CLND/ATOM`}
                </div>
                <ButtonPrimaryLarge
                  className="mt-[1rem] w-full"
                  onClick={btnAction}
                  disabled={btnDisabled()}
                  loading={loading}
                >
                  {hasAllowance ? props.actionType : 'Approve'}
                </ButtonPrimaryLarge>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
export default ActionDialog;
