import FastDeepEqual from 'fast-deep-equal/es6/react';
import moment from 'moment'; //导入模块

export const isEqual = FastDeepEqual;

export function pxToRem(px: number, scale = 16): number {
  const rem = px / scale;

  return rem;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasValue(value: any) {
  return !(value == null);
}

export function hasString(value?: string | undefined | null): boolean {
  if (value && value.length > 0) {
    return true;
  }
  return false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasFunction(value: any) {
  return typeof value === 'function';
}

export function getTimeDiff(endTimestamp, nowTimestamp, type) {
  return moment(endTimestamp).diff(moment(nowTimestamp), type);
}

export function getAssetImage(context: __WebpackModuleApi.RequireContext) {
  const images = new Map<string, string>();
  context.keys().forEach((key) => {
    const _key = key
      .split('./') // remove the first 2 characters
      .pop()
      ?.substring(0, key.length - 6) // remove the file extension
      ?.toUpperCase();
    if (_key) {
      images.set(_key, context(key));
    }
  });
  return images;
}
