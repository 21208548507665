import { Contract, Signer, BigNumber as BN, utils } from 'ethers';

import ARIDROP_ABI_json from './abis/Airdrop.json';

export class AirdropContract {
  private contract?: Contract;
  private signer?: Signer;
  private contractAddress?: string;

  constructor(address: string, signer: Signer) {
    this.contract = new Contract(address, ARIDROP_ABI_json, signer);
    this.signer = signer;
    this.contractAddress = address;
  }

  async decimal() {
    const decimals = BN.from(18);
    return decimals;
  }

  async calcGasLimit(gasLimit: string) {
    return BN.from(gasLimit).mul(12).div(10);
  }

  async claim(account: string, amount: string, merkleProof: string) {
    const address = await this.contract?.address;
    const value = utils.parseUnits(amount, 18);

    if (address) {
      const gasLimit = this.contract?.estimateGas?.claim(account, value, merkleProof);
      const overrides = { gasLimit };

      return this.contract?.claim(account, value, merkleProof, overrides);
    }
  }

  async merkleRoot() {
    try {
      return await this.contract?.merkleRoot();
    } catch (error) {
      console.log(error);
    }
  }

  async investors(address: string) {
    try {
      return await this.contract?.investors(address);
    } catch (error) {
      console.log(error);
    }
  }

  async getAccountBalanceInfo(address: string, amount: string) {
    const value = utils.parseUnits(amount, 18);

    try {
      return await this.contract?.getAccountBalanceInfo(address, value);
    } catch (error) {
      console.log(error);
    }
  }
}
