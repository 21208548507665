import { BigNumberish, BigNumber, Contract, ContractTransaction, Signer, utils, constants } from 'ethers';

import ERC20_ABI_json from './abis/ERC20.json';
import { mapEstimateGas, EstimateGas } from './tool';

export class TokenContract {
  private contract: Contract;
  private isEther: boolean;
  private estimateGas: EstimateGas;

  constructor(address: string, isEther: boolean, signer?: Signer) {
    this.isEther = isEther;
    this.contract = new Contract(address, isEther ? '[]' : ERC20_ABI_json, signer);
    this.estimateGas = mapEstimateGas(this.contract.estimateGas);
  }

  async decimals(): Promise<BigNumberish> {
    if (this.isEther) {
      return 18;
    }
    return this.contract.decimals();
  }

  async balance() {
    const decimals = await this.decimals();
    let balance: BigNumber;
    if (this.isEther) {
      balance = await this.contract.signer.getBalance('latest');
    } else {
      const address = await this.contract.signer.getAddress();
      balance = await this.contract.balanceOf(address);
    }
    return utils.formatUnits(balance, decimals);
  }

  async allowance(spender: string) {
    if (this.isEther) {
      return constants.MaxUint256.toString();
    }
    const address = await this.contract.signer.getAddress();
    const allowance = await this.contract.allowance(address, spender);
    const decimals = await this.contract.decimals();
    return utils.formatUnits(allowance, decimals);
  }

  async approve(spender: string, amount?: string): Promise<ContractTransaction> {
    const decimals = await this.contract.decimals();
    const value = amount ? utils.parseUnits(amount, decimals) : constants.MaxUint256;
    const gasLimit = await this.estimateGas.approve(spender, value);
    return this.contract.approve(spender, value, { gasLimit });
  }
}
