import React from 'react';
import classnames from 'classnames';

import { formatNumberInputValue } from '@/utils/input';

import styles from './index.module.css';

export interface InputProps {
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  placeholder?: string;
  precision: number;
  min?: number;
  max?: number;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  value?: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

function Input(props: InputProps) {
  const {
    precision,
    min,
    max,
    onChange,
    prefix,
    suffix,
    disabled,
    placeholder,
    onFocus,
    onBlur,
    className,
    inputClassName,
    value,
  } = props;

  const elemPrefix = <div className={styles.prefix}>{prefix}</div>;
  const elemSuffix = <div className={styles.suffix}>{suffix}</div>;

  const handleChange = React.useCallback(
    (event) => {
      const newValue = event.target.value.trim();
      const value = formatNumberInputValue(newValue, precision, min, max);

      if (onChange) {
        onChange(value);
      }
    },
    [max, min, onChange, precision]
  );

  const inputProps = {
    autoComplete: 'off',
    autoFocus: false,
    disabled,
    placeholder,
    type: 'text',
    value: value,
    onChange: handleChange,
    onFocus,
    onBlur,
  };

  return (
    <div className={classnames(styles.container, className)}>
      <input className={classnames(styles.input, inputClassName)} {...inputProps} />
      {elemPrefix}
      {elemSuffix}
    </div>
  );
}

export default Input;
