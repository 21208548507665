import BigNumber from 'bignumber.js';
import { BLANK_CHAR, isNumeric } from './operation';

export interface FormatNumberOptions {
  nilDisabled?: boolean;
  places?: number;
  thousands?: boolean;
  approximate?: boolean;
  prefix?: string;
  suffix?: string;
  sign?: boolean;
  digits?: number;
  rm?: BigNumber.RoundingMode;
  segmentValue?: string;
  segmentPlaces?: number;
}

const bigNumberFormat = (options: FormatNumberOptions): BigNumber.Format => {
  const { approximate, thousands, prefix, suffix } = options;
  return {
    prefix: approximate ? '≈ ' : prefix,
    decimalSeparator: '.',
    groupSeparator: ',',
    groupSize: thousands ? 3 : 0,
    secondaryGroupSize: 0,
    fractionGroupSeparator: ' ',
    fractionGroupSize: 0,
    suffix: suffix,
  };
};

export const getRoundDownFormats = (value: string, places = 8) => {
  const formats = { string: value, approximate: false };
  if (isNumeric(value)) {
    const number = new BigNumber(value);
    formats.string = number.decimalPlaces(places, BigNumber.ROUND_DOWN).toString();
    formats.approximate = number.decimalPlaces() > places;
  }
  return formats;
};

export const formatNumber4Loans = (value: string, options: FormatNumberOptions = {}) => {
  const opts = { ...{ places: 8, thousands: false, approximate: false }, ...options };
  const { rm = BigNumber.ROUND_DOWN } = options;
  if (!isNumeric(value)) {
    return opts.nilDisabled ? value : BLANK_CHAR;
  }
  const fmt = bigNumberFormat(opts);
  const number = new BigNumber(value);
  if (
    opts?.segmentValue &&
    opts?.segmentPlaces &&
    isNumeric(opts?.segmentValue) &&
    number.lt(new BigNumber(opts?.segmentValue))
  ) {
    opts.places = opts?.segmentPlaces;
  }
  return number.decimalPlaces(opts.places, rm).toFormat(fmt);
};

export const formatPercentages4Loans = (value: string, options: FormatNumberOptions = {}) => {
  const { sign = false } = options;
  const { rm = BigNumber.ROUND_DOWN } = options;
  const opts = { ...{ places: 2, thousands: false, suffix: '%' }, ...options };
  if (!isNumeric(value)) {
    return opts.nilDisabled ? value : BLANK_CHAR;
  }
  const number = new BigNumber(value);
  const numberFormat = number.times(100).decimalPlaces(opts.places, rm);
  if (sign) {
    const zero = new BigNumber('0');
    if (numberFormat.comparedTo(zero) > 0) {
      opts.prefix = '+';
    } else if (numberFormat.comparedTo(zero) < 0) {
      opts.prefix = '';
    } else {
      opts.prefix = '';
    }
  }
  const fmt = bigNumberFormat(opts);
  return numberFormat.toFormat(fmt);
};

export const formatCurrency4Loans = (value: string, options: FormatNumberOptions = {}) => {
  const opts = { ...{ places: 2, thousands: false, prefix: '$ ' }, ...options };
  const { rm = BigNumber.ROUND_DOWN } = options;
  if (!isNumeric(value)) {
    return opts.nilDisabled ? value : BLANK_CHAR;
  }
  const number = new BigNumber(value);
  if (number.gt(0) && opts.places === 2) {
    if (number.lt(0.001)) {
      opts.places = 4;
    } else if (number.lt(0.01)) {
      opts.places = 3;
    }
  }
  const fmt = bigNumberFormat(opts);
  return number.decimalPlaces(opts.places, rm).toFormat(fmt);
};

export const formatNumberAverage4Loans = (value: string, options: FormatNumberOptions = {}) => {
  if (!isNumeric(value)) {
    return options.nilDisabled ? value : BLANK_CHAR;
  }
  const { digits = 3, rm = BigNumber.ROUND_HALF_EVEN } = options;
  const si = [
    { value: '1', symbol: '' },
    { value: '1E3', symbol: 'K' },
    { value: '1E6', symbol: 'M' },
    { value: '1E9', symbol: 'B' },
    { value: '1E12', symbol: 'T' },
    { value: '1E15', symbol: 'P' },
    { value: '1E18', symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  let div;
  const num = new BigNumber(value);
  for (i = si.length - 1; i > 0; i--) {
    div = new BigNumber(si[i].value);
    if (num.comparedTo(div) >= 0) {
      break;
    }
  }
  div = new BigNumber(si[i].value);
  return num.div(div).toFixed(digits, rm).replace(rx, '$1') + si[i].symbol;
};

export const formatCurrencyAverage4Loans = (value: string, options: FormatNumberOptions = {}) => {
  if (!isNumeric(value)) {
    return options.nilDisabled ? value : BLANK_CHAR;
  }
  const { prefix = '$ ' } = options;
  const average = formatNumberAverage4Loans(value, options);
  return `${prefix}${average}`;
};
