import classNames from 'classnames';
import ImgDefault from '@/assets/coin/CLND.png';
import { getAssetImage } from '@/utils/helper';

export interface CoinIconProps {
  className?: string;
  name?: string;
}

const context = require.context('@/assets/coin', true, /.png$/);

function getCoinImg(name?: string) {
  if (name == null) {
    return ImgDefault;
  }
  const images = getAssetImage(context);
  return images.get(name.toUpperCase()) ?? ImgDefault;
}

function CoinIcon(props: CoinIconProps) {
  return (
    <img
      className={classNames('h-10 w-10 rounded-full border-2 border-[#333333]', props.className)}
      src={getCoinImg(props.name)}
      alt="coin"
    />
  );
}

export default CoinIcon;
