import { createContext, ReactNode, useCallback, useContext, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { BigNumber as BN } from 'ethers';
import { formatUnits } from '@ethersproject/units';
import { TransactionResponse } from '@ethersproject/providers';

import { getContracts } from '@/config/contracts';
import ABILens from '@/contracts/abis/Lens.json';
import { AccountBalancesInternal, AccountLimitsInternal, AccountMarket, Lens, MarketInternal } from '@/contracts/Lens';
import {
  accountAddressState,
  accountMarketsState,
  AccountOverview,
  accountOverviewState,
  marketsState,
} from '@/state/Wallet';
import { useContract } from './useContract';
import { bigGreaterThan, bigLessThanOrEqualTo, bigMul, bigPlus } from '@/utils/operation';
import { useComptroller } from './useComptroller';
import { formatNumber4Loans } from '@/utils/format';
import { hasString } from '@/utils/helper';

type DashboardContext = {
  getAll: () => Promise<void>;
};

const UseDashboardContext = createContext<DashboardContext | null>(null);

function DashboardProvider({ children }: { children: ReactNode }) {
  const { signerContract } = useContract();

  const contracts = getContracts();

  const setAccountMarkets = useSetRecoilState<AccountMarket[]>(accountMarketsState);
  const setAccountOverview = useSetRecoilState<AccountOverview>(accountOverviewState);

  const address = useRecoilValue(accountAddressState);

  const lens = signerContract<Lens>(contracts.lens, ABILens);

  const markets = useRecoilValue(marketsState);

  const getAll = useCallback(async () => {
    try {
      const [accountLimits, balances, metaData] = await lens?.callStatic?.allMarketsForAccount(address);

      const limits = accountLimits as AccountLimitsInternal;

      const assetsIn: { [key: string]: boolean } = {};
      limits?.markets?.forEach((item) => {
        assetsIn[item] = true;
      });

      let userTotalSupply = '0';
      let userTotalSupplyInMarket = '0';
      let userTotalBorrow = '0';
      let borrowCap = '0';

      const mantissa = BN.from(18);

      const all = (balances as AccountBalancesInternal[])?.map((item, index) => {
        const market = (metaData as MarketInternal[])[index];

        const collateralFactorMantissa = formatUnits(market.collateralFactorMantissa, mantissa);

        const totalSupply = formatUnits(market.totalSupply, market.marketDecimals);

        const price = formatUnits(market.price, mantissa.add(mantissa).sub(market.underlyingDecimals));

        const supplyBalanceOfUnderlying = formatUnits(item.balanceOfUnderlying, market.underlyingDecimals);
        const borrowBalance = formatUnits(item.borrowBalanceCurrent, market.underlyingDecimals);

        let userSupply: string | null | undefined;
        let userSupplyValue: string | null | undefined;
        let userBorrow: string | null | undefined;
        let userBorrowValue: string | null | undefined;
        if (bigLessThanOrEqualTo(totalSupply, 0)) {
          userSupply = '0';
          userSupplyValue = '0';
          userBorrow = '0';
          userBorrowValue = '0';
        } else {
          userSupply = supplyBalanceOfUnderlying;
          userSupplyValue = bigMul(userSupply, price);
          userBorrow = borrowBalance;
          userBorrowValue = bigMul(userBorrow, price);
        }

        userTotalSupply = bigPlus(userTotalSupply, userSupplyValue) ?? userTotalSupply;
        userTotalBorrow = bigPlus(userTotalBorrow, userBorrowValue) ?? userTotalBorrow;

        const isAssetIn = assetsIn[market.marketAddress];
        if (isAssetIn) {
          borrowCap = bigPlus(borrowCap, bigMul(userSupplyValue, collateralFactorMantissa)) ?? borrowCap;
          if (bigGreaterThan(collateralFactorMantissa, 0)) {
            userTotalSupplyInMarket = bigPlus(userTotalSupplyInMarket, userSupplyValue) ?? userTotalSupplyInMarket;
          }
        }

        const walletBalance = formatUnits(item.tokenBalance, market.underlyingDecimals);
        const allowance = formatUnits(item.tokenAllowance, market.underlyingDecimals);

        const underlyingDecimals = market.underlyingDecimals.toNumber();

        return {
          market: markets.find((m) => m.marketAddress === market.marketAddress),
          userSupply: formatNumber4Loans(userSupply ?? '', { places: underlyingDecimals }),
          userBorrow: formatNumber4Loans(userBorrow ?? '', { places: underlyingDecimals }),
          isAssetIn,
          walletBalance: walletBalance ?? '',
          allowance: allowance ?? '',
        };
      });
      // console.log(all);
      const liquidity = formatUnits(limits.liquidity, mantissa);

      setAccountMarkets(all ?? []);
      setAccountOverview({
        userTotalSupply,
        userTotalSupplyInMarket,
        userTotalBorrow,
        borrowCap,
        liquidity: liquidity,
      });
    } catch (err) {
      console.log(err);
    }
  }, [lens, address, markets, setAccountMarkets, setAccountOverview]);

  useEffect(() => {
    if (lens == null) {
      return;
    }
    if (!hasString(address)) {
      return;
    }
    if (markets.length === 0) {
      return;
    }
    getAll();
  }, [lens, address, markets, getAll]);

  return <UseDashboardContext.Provider value={{ getAll }}>{children}</UseDashboardContext.Provider>;
}

function useDashboard() {
  const context = useContext(UseDashboardContext);
  if (context === null) {
    throw new Error(
      'useDashboard() can only be used inside of <DashboardProvider />, please declare it at a higher level.'
    );
  }
  const { enterMarkets, exitMarket } = useComptroller();

  const overview = useRecoilValue(accountOverviewState);

  const switchCollateral = async (checked: boolean, data: AccountMarket) => {
    if (data.market == null) {
      return !checked;
    }
    let result: TransactionResponse | null = null;
    if (checked) {
      result = await enterMarkets([data.market?.marketAddress]);
    } else {
      const collateral = formatNumber4Loans(
        bigMul(bigMul(data.userSupply, data.market?.price), data.market?.collateralFactorMantissa) ?? '',
        { places: data.market?.underlyingDecimals }
      );
      if (bigGreaterThan(collateral, overview.liquidity)) {
        throw new Error('can not exit, insufficient account liquidity');
      }
      if (bigGreaterThan(data.userBorrow, 0)) {
        throw new Error('can not exit, repay borrow first');
      }
      result = await exitMarket(data.market?.marketAddress);
    }
    if (result == null) {
      return !checked;
    }
    await result.wait(1);
    await context.getAll();
    return checked;
  };

  return { ...context, switchCollateral };
}

export { useDashboard, DashboardProvider };
