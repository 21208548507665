import { SUPPORTED_CHAIN } from './tools';

export interface ContractsAddress {
  maximillion: string;
  lens: string;
  comptroller: string;
  claim: string;
  farms1: string;
  farms2: string;
  airdrop: string;
}

export interface TokenContractsAddress {
  clnd: string;
  tokenB: string;
  stableCoin: string;
  lpTokenAddress: string;
}

const CONTRACTS = {
  42: {
    maximillion: '0x81c6278163bdF028f8921e517892FF74b314d7Dd',
    lens: '0x78fbfb07adfCf3a2Db0eC3B2deD512F1Fcbba682',
    comptroller: '0x8862ab8DddB0360B86013fbb4609cf1EDCd3d56c',
    claim: '0xa53d0Dbc7B9aa0D20A6Bbb467d3Ff8935B1764a6',
    farms: '0xb15b021AC2f316017bA95374F330862F39096917',
    farms1: '0xb15b021AC2f316017bA95374F330862F39096917',
    farms2: '0xf7b929e07Dc6F046ed6AFc0ae9C1210BA30Ab3A2',
    airdrop: '',
  },
  9000: {
    maximillion: '0xc88A7328168DB597DF8cD37c1Df86e6293134Cf1',
    lens: '0x93e25046BC0A8e73D466F078DDEC02cA8c34610F',
    comptroller: '0xb371061511D81d0caE7e35086234a6177C322Cc3',
    claim: '0xE7dBE9C1a5A0b19Cf5FC590CF9Ea77742809fC57',
    farms1: '0xE4ea0230A1ead396FCEC32abbD2FCe14e067eC68',
    farms2: '0x6783eDDb6Fcc7190446c352a32C3fB929F18DC4c',
    airdrop: '0x8b426bB4384c3673D76198afD1DCed55CB127Be2',
  },
  9001: {
    maximillion: '',
    // lens: '0x8A956e7654cF3EfAcFA47e78Eb424fcDED1FAAF1',
    // comptroller: '0x2C8b48Dc777C26dc857E1040D8ef3Bdd3B1ef499',
    // lens: '0x0407FD1F355C8D3b4D3109cA33f8037006E6E9Af',
    // comptroller: '0x8eF2A976c3D7Ee3A1e600A7Df872Bd15939c0B22',
    lens: '0x1FdcADAc262C05772982922AC3008A57C3eBCe8C',
    comptroller: '0x5b32B588Af5F99F4e5c4038dDE6BDD991024F650',
    claim: '',
    farms1: '',
    farms2: '',
    airdrop: '',
  },
};

export const TOKENCONTRACTS = {
  42: {
    stableCoin: '0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa', // DAI
    tokenB: '0x31EeB2d0F9B6fD8642914aB10F4dD473677D80df', // need to change(TokenB)
    clnd: '0xdFc9621Ffafb9B69C6dF70aa01008610DB579ca0', // (TokenA)
    lpTokenAddress: '0x74Ee0aE78C5Ff79F022477e9BA6478FFf224F717', // MST
  },
  9000: {
    stableCoin: '0x8653a721DE382fc6654b530FE5290752e228e202', // DAI
    tokenB: '0x389a5b66685Fc6b7B1339376F0cF930B255Ca4E2', // need to change(TokenB) USDC
    clnd: '0x5b493cb9605e3391B45dbf076B400Ce48a3DEfd0', // (TokenA)
    lpTokenAddress: '0x7F7C0E8476895aEde57BF2a5108d765B1676B967', // MST
  },
  9001: {
    stableCoin: '',
    tokenB: '',
    clnd: '',
    lpTokenAddress: '',
  },
};

export function getTokenContracts(chainId: number = SUPPORTED_CHAIN): TokenContractsAddress {
  return TOKENCONTRACTS[chainId];
}

export function getContracts(chainId: number = SUPPORTED_CHAIN): ContractsAddress {
  return CONTRACTS[chainId];
}
