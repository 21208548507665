/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactNode, useContext, useMemo, useRef, useEffect, useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import detectEthereumProvider from '@metamask/detect-provider';
import { Web3Provider } from '@ethersproject/providers';
import WalletConnectProvider from '@walletconnect/web3-provider';

import { accountAddressState, chainIdState, connectActiveState } from '@/state/Wallet';
import { hasFunction, hasString } from '@/utils/helper';
import { getWalletName, setWalletName, removeWalletName, removeWalletConnect } from '@/utils/store';
import { getNetwork } from '@/config/network';
import { RPC_URLS } from '@/config/tools';

type WalletContext = {
  connect: (walletName: string) => Promise<void>;
  disconnect: () => void;
  provider: Web3Provider | undefined | null;
} | null;

const UseWalletContext = createContext<WalletContext>(null);

function WalletProvider({ children }: { children: ReactNode }) {
  const setAddress = useSetRecoilState(accountAddressState);
  const setChainId = useSetRecoilState(chainIdState);
  const setConnectActive = useSetRecoilState(connectActiveState);

  const activationId = useRef(0);
  const chainIdRef = useRef('');

  const [provider, setProvider] = useState<Web3Provider>();

  const walletConnectRef = useRef<WalletConnectProvider>();

  const hasConnector = useCallback(() => hasString(getWalletName()), []);

  const updateAccount = useCallback(
    (accounts: Array<string>) => {
      if (accounts.length > 0) {
        setAddress(accounts[0]);
      } else {
        setAddress('');
      }
    },
    [setAddress]
  );

  const disconnect = useCallback(
    async (clear = false) => {
      try {
        if (walletConnectRef.current?.isConnecting) {
          await walletConnectRef.current.disconnect();
        }
      } catch (err) {
        console.log(err);
      }
      setAddress('');
      removeWalletName();
      if (clear) {
        removeWalletConnect();
      }
      setConnectActive(hasConnector());
    },
    [setAddress, setConnectActive, hasConnector]
  );

  const setup = useCallback(
    async (ethereum, walletName) => {
      const _provider = new Web3Provider(ethereum);
      let accounts;
      if (walletName === 'MetaMask') {
        accounts = await _provider.send('eth_requestAccounts', []);
      } else {
        accounts = await _provider.listAccounts();
      }
      const { chainId } = await _provider.getNetwork();
      setProvider(_provider);
      setChainId(chainId);
      updateAccount(accounts);
      setWalletName(walletName);
      setConnectActive(hasConnector());
    },
    [hasConnector, updateAccount, setConnectActive, setChainId]
  );

  const connect = useCallback(
    async (walletName: string) => {
      const id = ++activationId.current;

      disconnect();

      // Check if another connection has happened right after deactivate().
      if (id !== activationId.current) {
        return;
      }

      let ethereum: any;
      if (walletName === 'MetaMask') {
        ethereum = (await detectEthereumProvider()) as any;
      } else if (walletName === 'WalletConnect') {
        ethereum = new WalletConnectProvider({
          rpc: RPC_URLS,
        });
        walletConnectRef.current = ethereum;
      }

      if (ethereum == null) {
        throw new Error('please install a wallet first!');
      }

      const handleConnect = (connectInfo: any) => {
        console.log('connect', connectInfo);
        const { chainId } = connectInfo ?? {};
        chainIdRef.current = chainId;
        setup(ethereum, walletName);
      };
      const handleDisconnect = (error: any) => {
        console.log('disconnect', error);
        disconnect(true);
      };
      const handleChainChanged = (chainId: any) => {
        console.log('chainChanged', chainId);
        if (chainIdRef.current !== chainId) {
          chainIdRef.current = chainId;
          setup(ethereum, walletName);
        }
      };
      const handleAccountsChanged = (accounts: Array<string>) => {
        console.log('accountsChanged', accounts);
        if (hasConnector()) {
          updateAccount(accounts);
        }
      };

      if (hasFunction(ethereum.off)) {
        ethereum.off('connect', handleConnect);
        ethereum.off('disconnect', handleDisconnect);
        ethereum.off('chainChanged', handleChainChanged);
        ethereum.off('accountsChanged', handleAccountsChanged);
      }

      ethereum.on('connect', handleConnect);
      ethereum.on('disconnect', handleDisconnect);
      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);

      if (walletName === 'WalletConnect') {
        await ethereum.enable();
      }
      setup(ethereum, walletName);
    },
    [disconnect, hasConnector, updateAccount, setup]
  );

  useEffect(() => {
    const name = getWalletName();
    if (hasString(name)) {
      connect(name);
    }
  }, [connect]);

  return <UseWalletContext.Provider value={{ connect, disconnect, provider }}>{children}</UseWalletContext.Provider>;
}

function useWallet() {
  const context = useContext(UseWalletContext);

  if (context === null) {
    throw new Error('useWallet() can only be used inside of <WalletProvider />, please declare it at a higher level.');
  }

  const switchOrAddChain = useCallback(
    async (chainId: number) => {
      const network = getNetwork(chainId);
      if (network.length === 0) {
        throw new Error('can not switch network, please switch in your wallet');
      }
      const params = network[0].params;
      const provider = context.provider?.provider as any;
      if (provider == null) {
        throw new Error('can not switch network, please switch in your wallet');
      }
      let result;
      try {
        result = await provider.request({
          method: 'wallet_switchEthereumChain',
          params: params,
        });
        console.log(result);
      } catch (err) {
        console.log(err);
        result = await provider.request({
          method: 'wallet_addEthereumChain',
          params: params,
        });
      }
      return result;
    },
    [context.provider]
  );

  return useMemo(() => {
    return { ...context, switchOrAddChain };
  }, [context, switchOrAddChain]);
}

export { useWallet, WalletProvider };
