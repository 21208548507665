import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';

export interface DialogProps {
  children: ReactNode;
  open: boolean;
  onClose: (value: boolean) => void;
  overlay?: boolean;
}

function CustomDialog(props: DialogProps) {
  const { children, open, onClose, overlay } = props;

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 overflow-y-auto bg-[#0056D6]/20 backdrop-blur-sm backdrop-filter"
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          {overlay ? (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
          ) : null}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-[25rem] transform overflow-hidden rounded-lg border border-white/10 bg-[#131415] p-6 text-left align-middle shadow-xl transition-all">
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default CustomDialog;
