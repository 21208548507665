import React from 'react';

type AsyncTask = () => Promise<void>;

export default function useLoading() {
  const [loading, setLoading] = React.useState(false);

  const loadingWith = React.useCallback(async (task: AsyncTask) => {
    setLoading(true);
    await task();
    setLoading(false);
  }, []);

  return { loading, loadingWith };
}
