import BigNumber from 'bignumber.js';

export function formatNumberInputValue(value: string, precision: number, min?: number, max?: number) {
  const step01 = value.replace(/[^\d\\.]/g, '');
  const step02 = step01.split('.');
  const step03 = step02.length > 2 ? `${step02[0]}.${step02.slice(1).join('')}` : step01;
  const step04 = step03.split('.');
  const step05 =
    precision > 0 && step04.length > 1 && step04[1].length > precision
      ? `${step04[0]}.${step04[1].substring(0, precision)}`
      : precision === 0
      ? step04[0]
      : step03;

  const step06 = min && new BigNumber(step05).isLessThan(min) ? min : step05;
  const step07 = max && new BigNumber(step06).isGreaterThan(max) ? max : step06;

  return step07.toString();
}

export function ellipsizeText(text: string, max: number, mode = 'middle', symbol = '…') {
  if (text.length <= max || symbol.length >= max) {
    return text;
  }

  const over = max - symbol.length;
  const startAt = over / 2 + (over % 2);

  let result = text;
  switch (mode) {
    case 'head':
      result = `${symbol}${text.slice(text.length - over)}`;
      break;
    case 'middle':
      result = `${text.slice(0, startAt)}${symbol}${text.slice(startAt === over ? text.length : startAt - over)}`;
      break;
    case 'tail':
    default:
      result = `${text.slice(0, over)}${symbol}`;
      break;
  }
  return result;
}
