// import React from 'react';

import { Popover } from '@headlessui/react';
import ImgHelp from '@/assets/common/help.png';

export const TIPS = {
  CollateralFactor:
    'The maximum amount users can borrow is limited by the collateral factors of the assets they have supplied. For example, if a user supplies 100 DAI as collateral, and the posted collateral factor for DAI is 75%, then the user can borrow at most 75 DAI worth of other assets at any given time. Each asset on Coslend can have a different collateral factor.',
  MaxWithdrawal:
    'When an asset is used as collateral for borrowing, the maximum withdrawal amount is limited. To prevent your collateral assets from being liquidated due to taking out too many assets.',
  MaxBorrow:
    'Max Borrow represents the amount of a given asset that can be borrowed under 90% of the current borrowing capacity.\nSet aside 10% to prevent your collateral assets from being liquidated due to large price fluctuations.\nMax Borrow = 90% x ∑(Collateral Amount × Collateral Price x Collateral Factor) / Borrow Asset Price',
  LTV: `Loan to Value (LTV) ratio refers to the ratio of the user's current loan value to the market value of all collaterals.\nLTV = ∑(Borrow Amount × Borrow Asset Price) / ∑(Collateral Amount × Collateral Price)`,
  MaxLTV: `Max LTV refers to the ratio of the user's current 90% borrowing capacity to the market value of all collaterals. Set aside 10% to prevent your collateral assets from being liquidated due to large price fluctuations.\nMax LTV = 90% x ∑(Collateral Amount x Collateral Price x Collateral Factor) /  ∑(Collateral Amount x Collateral Price)`,
};

export const Pop = (props: { data: string | undefined }) => {
  const { data } = props;
  return (
    <Popover className="relative h-4">
      <Popover.Button className="outline-none">
        <img className="ml-1 mb-1 h-4 w-4" src={ImgHelp} alt="" />
      </Popover.Button>
      <Popover.Panel className="absolute -left-28 z-10">
        <p className="w-64 whitespace-pre-wrap rounded-lg border border-white/10 bg-[#1f1f1f] p-4 text-left text-sm font-normal text-white/80 outline-none">
          {data}
        </p>
      </Popover.Panel>
    </Popover>
  );
};
