/* eslint-disable @typescript-eslint/no-explicit-any */
import { BigNumber, Contract, Overrides } from 'ethers';
import { TransactionResponse } from '@ethersproject/providers';
import ABIClaim from './abis/Claim.json';

export interface Claim extends Contract {
  pendingAll: (user: string) => Promise<BigNumber[]>;
  claimAll: (user: string, overrides?: Overrides) => Promise<TransactionResponse>;
  claimDistribution: (user: string, overrides?: Overrides) => Promise<TransactionResponse>;
}

export const ABI = ABIClaim;
