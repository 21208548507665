import { Contract, ContractInterface } from 'ethers';

import { useWallet } from './useWallet';
import { FarmsContract } from '@/contracts/FarmsContract';
import { AirdropContract } from '@/contracts/AirdropContract';
import { hasString } from '@/utils/helper';

export function useContract() {
  const { provider } = useWallet();

  const signerContract = <T extends Contract>(contract: string, abi: ContractInterface): T | undefined => {
    if (provider && hasString(contract)) {
      const signer = provider.getSigner();
      return new Contract(contract, abi, signer) as T;
    }
    return undefined;
  };

  const FarmContract = (address: string) => {
    if (provider) {
      const signer = provider.getSigner();
      return new FarmsContract(address, signer);
    }
    return undefined;
  };

  const AirdropsContract = (address: string) => {
    if (provider) {
      const signer = provider.getSigner();
      return new AirdropContract(address, signer);
    }
    return undefined;
  };

  return { signerContract, FarmContract, AirdropsContract };
}
