import { useEffect, useRef } from 'react';

function useInterval(callback: () => void, delay: number = 15 * 1000) {
  const savedCallback = useRef<() => void>();
  const id = useRef<NodeJS.Timer>();

  useEffect(() => {
    // no need deep equal
    if (savedCallback.current !== callback) {
      clearInterval(id.current);
      savedCallback.current = callback;
    }
    savedCallback.current?.();
    id.current = setInterval(savedCallback.current, delay);
    return () => clearInterval(id.current);
  }, [callback, delay]);
}

export { useInterval };
