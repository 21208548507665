import { BigNumber } from 'ethers';

export const ETHER_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';

export const isEther = (address: string | undefined | null) => address === ETHER_ADDRESS;

export const calcGasLimit = (gasLimit: string | BigNumber, multiple = 12) => {
  return BigNumber.from(gasLimit).mul(multiple).div(10);
};

export const SUPPORTED_CHAIN = 9001;
// export const SUPPORTED_CHAIN = 9000;
// export const SUPPORTED_CHAIN = 42;
export const isSupportedChain = (chainId: number) => chainId === SUPPORTED_CHAIN;

const EXPLORER_URL = {
  42: 'https://kovan.etherscan.io',
  9000: 'https://evm.evmos.dev',
  9001: 'https://evm.evmos.org',
};

export const getExplorer = (chainId: number = SUPPORTED_CHAIN) => {
  return EXPLORER_URL[chainId];
};

export const RPC_URLS = {
  9000: 'https://eth.bd.evmos.dev:8545',
  9001: 'https://eth.bd.evmos.org:8545',
};
