import React from 'react';

import { useWallet } from './useWallet';
import { CTokenContract } from '@/contracts';
import { Market } from '@/contracts/Lens';
import { isEther } from '@/config/tools';

export default function useCTokenContract(market?: Market) {
  const wallet = useWallet();
  const signer = wallet.provider?.getSigner();

  const address = market?.marketAddress ?? '';

  return React.useMemo(
    () => new CTokenContract(address, isEther(market?.underlyingAddress), signer),
    [signer, address, market?.underlyingAddress]
  );
}
