import { useContract } from './useContract';
import { Comptroller, ABI } from '@/contracts/Comptroller';
import { calcGasLimit } from '@/config/tools';
import { getContracts } from '@/config/contracts';

export function useComptroller() {
  const { signerContract } = useContract();
  const { comptroller } = getContracts();

  const enterMarkets = async (marketAddress: string[]) => {
    if (comptroller == null) {
      return null;
    }
    const contract = signerContract<Comptroller>(comptroller, ABI);
    if (contract == null) {
      return null;
    }
    const gasLimit = await contract.estimateGas.enterMarkets(marketAddress);
    // console.log(gasLimit.toString());
    // const gasPrice = await signer.getGasPrice();
    // console.log(gasPrice.toString());
    const overrides = {
      gasLimit: calcGasLimit(gasLimit),
      // gasPrice: ,
    };

    const result = await contract.enterMarkets(marketAddress, overrides);

    return result;
  };

  const exitMarket = async (marketAddress: string) => {
    if (comptroller == null) {
      return null;
    }
    const contract = signerContract<Comptroller>(comptroller, ABI);
    if (contract == null) {
      return null;
    }
    const gasLimit = await contract.estimateGas.exitMarket(marketAddress);
    // console.log(gasLimit.toString());
    // const gasPrice = await signer.getGasPrice();
    // console.log(gasPrice.toString());
    const overrides = {
      gasLimit: calcGasLimit(gasLimit),
      // gasPrice: ,
    };

    const result = await contract.exitMarket(marketAddress, overrides);

    return result;
  };

  return { enterMarkets, exitMarket };
}
