// import { useRef, useState } from 'react';
import { Tab } from '@headlessui/react';
import classnames from 'classnames';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { ButtonPrimaryMedim } from '@/components/Button';
import CoinIcon from '@/components/CoinIcon';
import { Notify } from '@/components/Notify';

import { marketsState, overviewState } from '@/state/Wallet';
import { formatCurrency4Loans, formatNumber4Loans, formatPercentages4Loans } from '@/utils/format';
import { Market } from '@/contracts/Lens';

import bg2 from '@/assets/about/bg2.webp';
import ImgHot from '@/assets/common/hot.png';
import { useCallback } from 'react';

const Overview = () => {
  const overview = useRecoilValue(overviewState);

  return (
    <div className="mx-auto w-full max-w-[45rem] rounded-lg bg-gradient-to-r from-[#e921c3] to-[#0067ff] p-px">
      <div className="grid grid-cols-12 gap-y-6 rounded-lg bg-black p-6 text-center">
        <div className="col-span-12 sm:col-span-6">
          <div className="text-sm font-normal text-white/50">Total Supply</div>
          <span className="bg-gradient-to-r from-[#e921c3] to-[#0067ff] bg-clip-text text-3xl font-bold text-transparent">
            {formatCurrency4Loans(overview.totalSupply, { thousands: true })}
          </span>
        </div>
        <div className="col-span-12 sm:col-span-6">
          <div className="text-sm font-normal text-white/50">Total Borrow</div>
          <span className="bg-gradient-to-r from-[#e921c3] to-[#0067ff] bg-clip-text text-3xl font-bold text-transparent">
            {formatCurrency4Loans(overview.totalBorrow, { thousands: true })}
          </span>
        </div>
      </div>
    </div>
  );
};

const Row = (props: { data: Market }) => {
  const { data } = props;

  const navigate = useNavigate();

  return (
    <div className="mb-2 grid grid-cols-2 rounded-lg border border-white/10 bg-[#131415] p-6 text-base font-bold text-white last:mb-0 lg:h-[128px] lg:grid-cols-[14%_26%_24%_24%_12%]">
      <div className="col-span-2 flex items-center lg:col-auto">
        <CoinIcon name={data.underlyingSymbol} />
        <div className="ml-4">
          <div className="text-[1.375rem]">{data.underlyingSymbol}</div>
          <div className="mt-0.5 text-sm font-normal text-white/50">{formatCurrency4Loans(data.price)}</div>
        </div>
      </div>
      <div className="col-span-2 mt-4 mb-1.5 flex items-center lg:col-auto lg:mt-0 lg:mb-0 lg:px-5 xl:px-10">
        <div className="w-full">
          <div>Asset</div>
          <div className="mt-1 flex items-center justify-between">
            <div className="text-sm font-normal text-white/50">Total Supply</div>
            <div>{formatNumber4Loans(data.totalSupply, { places: 2, thousands: true })}</div>
          </div>
          <div className="mt-0.5 flex items-center justify-between">
            <div className="text-sm font-normal text-white/50">Total Borrow</div>
            <div>{formatNumber4Loans(data.totalBorrows, { places: 2, thousands: true })}</div>
          </div>
        </div>
      </div>
      <div className="col-span-2 flex items-center border-t border-b border-white/10 py-1.5 lg:col-auto lg:mt-0 lg:border-t-0 lg:border-b-0 lg:border-l lg:border-r lg:py-0 lg:px-5 xl:px-10">
        <div className="w-full">
          <div>APY</div>
          <div className="mt-1 flex items-center justify-between">
            <div className="text-sm font-normal text-white/50">Supply APY</div>
            <div>{formatPercentages4Loans(data.supplyApy)}</div>
          </div>
          <div className="mt-0.5 flex items-center justify-between">
            <div className="text-sm font-normal text-white/50">Borrow APY</div>
            <div>{formatPercentages4Loans(data.borrowApy)}</div>
          </div>
        </div>
      </div>
      <div className="col-span-2 mt-1.5 lg:col-auto lg:mt-0 lg:px-5 xl:px-10">
        <div className="flex h-full w-full flex-col">
          <div className="flex items-baseline">
            <div className="flex items-center">
              <CoinIcon className="h-4 w-4" name="CLND" />
              <div className="ml-1">CLND Rewards</div>
            </div>
            {/* <img className="ml-1 h-6 w-6" src={ImgHot} alt="" />
            <i className="ml-0.5 text-base font-bold text-[#e921c3]">12x</i> */}
          </div>
          {/* <div className="mt-1 flex items-center justify-between">
            <div className="text-sm font-normal text-white/50">Supply APY</div>
            <div>{formatPercentages4Loans(data.supplyMineApy)}</div>
          </div>
          <div className="mt-0.5 flex items-center justify-between">
            <div className="text-sm font-normal text-white/50">Borrow APY</div>
            <div>{formatPercentages4Loans(data.borrowMineApy)}</div>
          </div> */}
          <div className="mt-1 flex flex-1 items-center text-sm font-normal text-white/50">Coming soon</div>
        </div>
      </div>
      <div className="col-span-2 mt-[1.125rem] lg:col-auto lg:mt-0">
        <div className="flex items-center lg:-mt-1 lg:block lg:w-full">
          <ButtonPrimaryMedim
            className="w-full flex-1"
            onClick={() => {
              navigate('/lend', {
                state: { marketAddress: data.marketAddress, type: 'Supply' },
              });
            }}
          >
            Supply
          </ButtonPrimaryMedim>
          <ButtonPrimaryMedim
            className="mt-0 ml-4 w-full flex-1 lg:ml-0 lg:mt-2"
            onClick={() => {
              navigate('/lend', {
                state: { marketAddress: data.marketAddress, type: 'Borrow' },
              });
            }}
          >
            Borrow
          </ButtonPrimaryMedim>
        </div>
      </div>
    </div>
  );
};

const VerifiedMarkets = () => {
  const list = useRecoilValue(marketsState);

  return (
    <div>
      {list.map((item, index) => (
        <Row data={item} key={index} />
      ))}
    </div>
  );
};

const Tabs = () => {
  return (
    <Tab.List className="mb-4 flex space-x-6 border-b border-white/20 lg:space-x-12">
      <Tab
        className={({ selected }) =>
          classnames(
            'py-4 text-[1.375rem] font-bold',
            selected ? 'border-b border-[#e921c3] text-white' : 'text-white/50  hover:text-white'
          )
        }
      >
        Verified Markets
      </Tab>
      <button
        className="py-4 text-[1.375rem] font-bold text-white/50  hover:text-white"
        onClick={() => {
          Notify.coming();
        }}
      >
        Risky Markets
      </button>
    </Tab.List>
  );
};

function Markets() {
  return (
    <div className="relative mx-auto flex flex-col items-center bg-transparent pt-px">
      <img className="fixed h-[53.5rem] object-fill md:w-full" src={bg2} alt="" />
      <p className=" mt-8 bg-gradient-to-r from-[#e921c3] to-[#0067ff] bg-clip-text text-4xl font-bold text-transparent">
        Markets
      </p>
      <div className="relative mx-auto mt-2 w-full max-w-[74.5rem] px-4 sm:px-16 lg:px-8">
        <Overview />
        <div className="mt-4">
          <Tab.Group>
            <Tabs />
            <Tab.Panels>
              <Tab.Panel>
                <VerifiedMarkets />
              </Tab.Panel>
              <Tab.Panel>
                <div />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
}

export default Markets;
