import { Routes, Route } from 'react-router-dom';
import { WalletProvider } from '@/hooks/useWallet';
// import DashboardModel from '@/context/Dashboard/useDashboard';

import Footer from '@/components/Footer';
import Header from '@/components/Header';

import About from './about';
import Pools from './pools';
import Markets from './markets';
import Lend from './lend';
import Dashboard from './dashboard';
import { MarketsProvider } from '@/hooks/useMarkets';
import { DashboardProvider } from '@/hooks/useDashboard';
import Network from '@/components/Network';
import { ClaimProvider } from '@/hooks/useClaim';
import { FarmProvider } from '@/hooks/useFarm';

function App() {
  const ElementPools = () => (
    <FarmProvider>
      <Network>
        <Pools />
      </Network>
    </FarmProvider>
  );

  const ElementMarkets = () => (
    <Network>
      <Markets />
    </Network>
  );

  const ElementLend = () => (
    <Network>
      <Lend />
    </Network>
  );

  const ElementDashboard = () => (
    <Network>
      <Dashboard />
    </Network>
  );

  return (
    <WalletProvider>
      <MarketsProvider>
        <DashboardProvider>
          <ClaimProvider>
            <div className="flex min-h-screen flex-col bg-black tracking-tight">
              <Header />
              <div className="flex-auto pt-20 md:pt-24">
                <Routes>
                  <Route path="/">
                    <Route index element={<About />} />
                    {/* <Route path="pools" element={<ElementPools />} /> */}
                    <Route path="markets" element={<ElementMarkets />} />
                    <Route path="lend" element={<ElementLend />} />
                    <Route path="dashboard" element={<ElementDashboard />} />
                    <Route path="*" element={<About />} />
                  </Route>
                </Routes>
              </div>
              <Footer />
            </div>
          </ClaimProvider>
        </DashboardProvider>
      </MarketsProvider>
    </WalletProvider>
  );
}

export default App;
