import classNames from 'classnames';

import styles from './index.module.css';

export interface LTVProgressProps {
  className?: string;
  current?: number | null;
  max?: number | null;
  liquidation?: number | null;
}

function LTVProgress(props: LTVProgressProps) {
  let overlayWidth = (props.current ?? 0) * 100;
  let maxLeft = (props.max ?? 0) * 100;
  let liquidationLeft = (props.liquidation ?? 0) * 100;
  if (overlayWidth > 100) {
    overlayWidth = 100;
  }
  if (maxLeft > 100) {
    maxLeft = 100;
  }
  if (liquidationLeft > 100) {
    liquidationLeft = 100;
  }
  let overlayMax = 100;
  if (overlayWidth > maxLeft) {
    overlayMax = (maxLeft / overlayWidth) * 100;
  }

  return (
    <div className={classNames(styles.progress, props.className)}>
      <div
        className={styles.overlay}
        style={{
          width: `${overlayWidth}%`,
          background:
            overlayMax < 100
              ? `linear-gradient(90deg, #0067FF 0%, #E921C3 ${overlayMax}%, #FF143F 100%)`
              : `linear-gradient(90deg, #0067FF 0%, #E921C3 100%)`,
        }}
      />
      {(props.max ?? 0) > 0 ? (
        <div className={classNames(styles.mark, styles.max)} style={{ left: `calc(${maxLeft}% - 0.5px)` }} />
      ) : null}
      {(props.max ?? 0) > 0 ? (
        <div
          className={classNames(styles.mark, styles.liquidation)}
          style={{ left: `calc(${liquidationLeft}% - 0.5px)` }}
        />
      ) : null}
    </div>
  );
}
export default LTVProgress;
