import React from 'react';
import Pools from '@/components/Pools';

import Airdrop from '@/components/Pools/airdrop';

// import { pxToRem } from '@/utils/helper';
import bg2 from '@/assets/about/bg2.webp';
import style from './index.module.css';

// import Notification from '@/components/Notification';

// function setHeight(ref: { current: HTMLDivElement | null }, set: (arg0: number) => void) {
//   if (ref.current) {
//     const boxheight = ref.current.clientHeight;
//     const boxRem = pxToRem(boxheight);

//     set(15.6 + boxRem);
//   }
// }

function App() {
  // console.log(useFarms());
  // const [height, setPoolhHeight] = React.useState(0);
  // const boxRef = React.useRef<HTMLDivElement>(null);

  // React.useEffect(() => {
  //   setHeight(boxRef, setPoolhHeight);

  //   window.addEventListener('resize', resizeThrottler, false);
  // }, []);

  // const resizeThrottler = (target: object) => {
  //   setHeight(boxRef, setPoolhHeight);
  // };
  return (
    <div
      className={`container mx-auto flex flex-col items-center bg-transparent pt-px`}
      // style={{ height: `${height}rem` }}
    >
      <img className="fixed h-[53.5rem] object-fill md:w-full" src={bg2} alt="" />
      <div className={style.pools}>
        <span className={style.title}>Pools</span>
      </div>
      <div className={style.poolsarea}>
        {/* <FarmProvider2>
          <Pools2 farmType="2" />
        </FarmProvider2> */}
        <Pools stage="1" farmType="1" />
        <Airdrop />
      </div>
    </div>
  );
}

export default App;
