import { isEther } from '@/config/tools';
import { Contract, Signer, utils, BigNumber as BN } from 'ethers';

import Farms_ABI_json from './abis/Farm.json';
import { TokenContract } from './TokenContract';
import { LpTokenContract } from './LpTokenContract';

export class FarmsContract {
  private contract?: Contract;
  private signer?: Signer;
  private contractAddress?: string;

  constructor(address: string, signer: Signer) {
    this.contract = new Contract(address, Farms_ABI_json, signer);
    this.signer = signer;
    this.contractAddress = address;
  }

  async decimal() {
    const decimals = BN.from(18);
    return decimals;
  }

  async calcGasLimit(gasLimit: string) {
    return BN.from(gasLimit).mul(12).div(10);
  }

  //token balance ==> user in farm
  async farmUserTokenBalance(tokenAddress: string) {
    return new TokenContract(tokenAddress, isEther(tokenAddress), this.signer).balance();
  }

  // farm's lptoken balance
  async farmLpTokenBalance(tokenAddress: string, farmsAddress: string) {
    return new LpTokenContract(tokenAddress, isEther(tokenAddress), this.signer).balance(farmsAddress);
  }

  async farmUserAllowance(spender: string, tokenAddress: string) {
    return new TokenContract(tokenAddress, isEther(tokenAddress), this.signer).allowance(spender);
  }

  async farmUserApprove(spender: string, tokenAddress: string, amount?: string) {
    return new TokenContract(tokenAddress, isEther(tokenAddress), this.signer).approve(spender, amount);
  }

  async unStake(amount: string) {
    const address = await this.contract?.address;

    if (address) {
      const value = utils.parseUnits(amount, await this.decimal());

      const gasLimit = this.contract?.estimateGas.withdraw(value);

      const overrides = { gasLimit };

      return this.contract?.withdraw(value, overrides);
    }
  }

  async stake(amount: string) {
    const address = await this.contract?.address;

    if (address) {
      const value = utils.parseUnits(amount, await this.decimal());

      const gasLimit = this.contract?.estimateGas.stake(value);

      const overrides = { gasLimit };

      return this.contract?.stake(value, overrides);
    }
  }

  async claim() {
    const address = await this.contract?.address;

    if (address) {
      const gasLimit = await (isEther(address) ? this.contract?.claim() : await this.contract?.estimateGas?.claim());
      const overrides = { gasLimit };

      return this.contract?.claim(overrides);
    }
  }

  async userInfo(address: string) {
    try {
      return await this.contract?.userInfo(address);
    } catch (error) {
      console.log(error);
    }
  }

  async pending(address: string) {
    try {
      return await this.contract?.pending(address);
    } catch (error) {
      console.log(error);
    }
  }

  async dayElapsed() {
    try {
      return await this.contract?.dayElapsed();
    } catch (error) {
      console.log(error);
    }
  }

  async durationInDays() {
    try {
      return await this.contract?.durationInDays();
    } catch (error) {
      console.log(error);
    }
  }

  async rewardPerSecond() {
    try {
      return await this.contract?.rewardPerSecond();
    } catch (error) {
      console.log(error);
    }
  }

  async getEndTime() {
    try {
      return await this.contract?.endTime();
    } catch (error) {
      console.log(error);
    }
  }
}
