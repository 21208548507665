import { BigNumber } from 'ethers';

const networks = [
  {
    params: [
      {
        chainId: '0x1',
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x2a',
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x2328',
        chainName: 'Evmos Testnet',
        nativeCurrency: {
          name: 'test-Evmos',
          symbol: 'tEVMOS',
          decimals: 18,
        },
        rpcUrls: ['https://eth.bd.evmos.dev:8545'],
        blockExplorerUrls: ['https://evm.evmos.dev'],
        // iconUrls: [],
      },
    ],
  },
  {
    params: [
      {
        chainId: '0x2329',
        chainName: 'Evmos',
        nativeCurrency: {
          name: 'Evmos',
          symbol: 'EVMOS',
          decimals: 18,
        },
        rpcUrls: ['https://eth.bd.evmos.org:8545'],
        blockExplorerUrls: ['https://evm.evmos.org'],
        // iconUrls: [],
      },
    ],
  },
];

export const getNetwork = (chainid: number) => {
  return networks.filter((item) => item.params[0].chainId === BigNumber.from(chainid).toHexString());
};

export default networks;
