import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Switch, Tab } from '@headlessui/react';
import classnames from 'classnames';
import { useRecoilValue } from 'recoil';

import { ButtonOutlineSmall, ButtonPrimaryMedim } from '@/components/Button';
import CoinIcon from '@/components/CoinIcon';
import { Notify } from '@/components/Notify';
import { Pop, TIPS } from '@/components/Pop';
import { useClaim } from '@/hooks/useClaim';

import {
  accountAddressState,
  accountBorrowMarketsState,
  accountOverviewState,
  accountPendingClaimState,
  accountSupplyMarketsState,
} from '@/state/Wallet';

import { formatCurrency4Loans, formatNumber4Loans, formatPercentages4Loans } from '@/utils/format';
import { bigDiv, bigGreaterThan, bigLessThanOrEqualTo, bigPlus } from '@/utils/operation';
import { AccountMarket } from '@/contracts/Lens';

import { useDashboard } from '@/hooks/useDashboard';

import bg2 from '@/assets/about/bg2.webp';
import ImgEmpty from '@/assets/common/empty.png';

import styles from './index.module.css';

const Overview = () => {
  const overview = useRecoilValue(accountOverviewState);
  const { pendingDistributionI, pendingDistributionII } = useRecoilValue(accountPendingClaimState);
  const address = useRecoilValue(accountAddressState);

  const ltv = bigDiv(overview.userTotalBorrow, overview.userTotalSupplyInMarket) ?? '';
  const liquidation = bigDiv(overview.borrowCap, overview.userTotalSupplyInMarket) ?? '';

  const { pendingAll, claimDistribution } = useClaim();

  const [loading, setLoading] = useState(false);
  const claim = async () => {
    setLoading(true);
    try {
      const result = await claimDistribution(address);
      await result?.wait(1);
      await pendingAll();
      Notify.success('Claim Success');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log(err);
      Notify.error(err.message);
    }
    setLoading(false);
  };
  // const pending = bigPlus(pendingDistributionI, pendingDistributionII);
  const pending = pendingDistributionI;
  return (
    <div className="grid w-full grid-cols-12 gap-x-2 gap-y-2 lg:grid-cols-[72.5%_27.5%]">
      <div className="col-span-12 grid grid-cols-12 gap-y-2 rounded-lg border border-[#e921c3] bg-black p-6 text-center lg:col-auto">
        <div className="col-span-12 md:col-span-6 xl:col-span-3">
          <div className="text-sm font-normal text-white/50">Total Supply</div>
          <div className="text-[1.375rem] font-bold text-white">
            {formatCurrency4Loans(overview.userTotalSupply, { thousands: true })}
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 xl:col-span-3">
          <div className="text-sm font-normal text-white/50">Total Borrow</div>
          <div className="text-[1.375rem] font-bold text-white">
            {formatCurrency4Loans(overview.userTotalBorrow, { thousands: true })}
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 xl:col-span-3">
          <div className="flex items-center justify-center">
            <div className="text-sm font-normal text-white/50">Loan to value</div>
            <Pop data={TIPS.LTV} />
          </div>
          <span className="bg-gradient-to-r from-[#e921c3] to-[#0067ff] bg-clip-text text-[1.375rem] font-bold text-transparent">
            {formatPercentages4Loans(ltv)}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 xl:col-span-3">
          <div className="text-sm font-normal text-white/50">Liquidation LTV</div>
          <div className="text-[1.375rem] font-bold text-white">{formatPercentages4Loans(liquidation)}</div>
        </div>
      </div>
      <div className="col-span-12 rounded-lg bg-gradient-to-r from-[#e921c3] to-[#0067ff] p-px lg:col-auto">
        <div className="grid h-full grid-cols-12 gap-y-2 rounded-lg bg-black p-6 lg:grid-rows-2 xl:grid-rows-1">
          <div className="col-span-12 flex flex-col items-center md:col-span-6 lg:col-span-12 lg:row-span-1 xl:col-span-8 xl:row-span-1">
            <div className="flex items-center justify-center text-sm font-normal text-white/50">
              <CoinIcon className="h-4 w-4" name="CLND" />
              <span className="ml-1">Rewards</span>
            </div>
            <div className="text-[1.375rem] font-bold text-white">
              {/* {formatNumber4Loans(pending ?? '', {
                places: 2,
                segmentValue: '1',
                segmentPlaces: 8,
              })} */}
              Coming soon
            </div>
          </div>
          <div className="col-span-12 flex items-center justify-center md:col-span-6 lg:col-span-12 lg:row-span-1 xl:col-span-4 xl:row-span-1 xl:justify-end">
            <ButtonOutlineSmall
              onClick={claim}
              // disabled={bigLessThanOrEqualTo(pending, 0, true)}
              disabled={true}
              loading={loading}
            >
              Claim
            </ButtonOutlineSmall>
          </div>
        </div>
      </div>
    </div>
  );
};

const Row = (props: { data: AccountMarket; type: string }) => {
  const { data, type } = props;

  const [enabled, setEnabled] = useState(data.isAssetIn);

  const { switchCollateral } = useDashboard();

  const [loading, setLoading] = useState(false);

  let title = '';
  let apy = '';
  let amount = '';
  let collateral = false;
  let button1 = '';
  let button2 = '';
  switch (type) {
    case 'Supply':
      title = 'Supply APY';
      // apy = `${formatPercentages4Loans(data.market?.supplyApy ?? '')} + ${formatPercentages4Loans(
      //   data.market?.supplyMineApy ?? ''
      // )}`;
      apy = `${formatPercentages4Loans(data.market?.supplyApy ?? '')}`;
      amount = data.userSupply;
      collateral = bigGreaterThan(data.market?.collateralFactorMantissa, 0);
      button1 = 'Supply';
      button2 = 'Withdraw';
      break;
    case 'Borrow':
      title = 'Borrow APY';
      // apy = `${formatPercentages4Loans(data.market?.borrowApy ?? '')} - ${formatPercentages4Loans(
      //   data.market?.borrowMineApy ?? ''
      // )}`;
      apy = `${formatPercentages4Loans(data.market?.borrowApy ?? '')}`;
      amount = data.userBorrow;
      collateral = false;
      button1 = 'Borrow';
      button2 = 'Repay';
      break;
  }

  const handleSwitch = async (checked: boolean) => {
    setLoading(true);
    let _checked = checked;
    try {
      _checked = await switchCollateral(checked, data);
      Notify.success(checked ? 'Enter Success' : 'Exit Success');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log(err);
      Notify.error(err.message);
      _checked = !checked;
    }
    setLoading(false);
    setEnabled(_checked);
  };

  const navigate = useNavigate();

  return (
    <div className="mb-2 grid grid-cols-2 rounded-lg border border-white/10 bg-[#131415] px-6 pt-4 pb-6 text-base font-bold text-white last:mb-0 lg:h-[88px] lg:grid-cols-[15%_18%_18%_18%_12.5%_18.5%] lg:pt-6">
      <div className="col-span-2 flex items-center lg:col-auto">
        <CoinIcon name={data.market?.underlyingSymbol} />
        <div className="ml-4 text-[1.375rem]">{data.market?.underlyingSymbol}</div>
      </div>
      <div className="col-span-2 mt-4 flex items-center justify-between lg:col-auto lg:mt-0">
        <div className="text-sm font-normal text-white/50 lg:hidden">{title}</div>
        <div>{apy}</div>
      </div>
      <div className="col-span-2 mt-[0.125rem] flex items-center justify-between lg:col-auto lg:mt-0">
        <div className="text-sm font-normal text-white/50 lg:hidden">Amount</div>
        <div>{formatNumber4Loans(amount, { places: 4, thousands: true })}</div>
      </div>
      <div className="col-span-2 mt-[0.125rem] flex items-center justify-between lg:col-auto lg:mt-0">
        <div className="text-sm font-normal text-white/50 lg:hidden">Wallet Balance</div>
        <div>{formatNumber4Loans(data.walletBalance, { places: 4, thousands: true })}</div>
      </div>
      {collateral ? (
        <div className="col-span-2 mt-2 flex items-center justify-between lg:col-auto lg:mt-0 lg:justify-center">
          <div className="text-sm font-normal text-white/50 lg:hidden">Collateral</div>
          {loading ? (
            <div className={styles.loading} />
          ) : (
            <Switch
              checked={enabled}
              onChange={handleSwitch}
              className={`${
                enabled ? 'bg-[#e921c3]' : 'bg-white/30'
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span
                className={`${
                  enabled ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white`}
              />
            </Switch>
          )}
        </div>
      ) : (
        <div className="col-span-2 lg:col-auto " />
      )}
      <div className="col-span-2 mt-6 flex items-center lg:col-auto lg:mt-0">
        <ButtonPrimaryMedim
          className="flex-1 px-2"
          onClick={() => {
            navigate('/lend', { state: { marketAddress: data.market?.marketAddress, type: button1 } });
          }}
        >
          {button1}
        </ButtonPrimaryMedim>
        <ButtonPrimaryMedim
          className="ml-2 flex-1 px-2"
          onClick={() => {
            navigate('/lend', { state: { marketAddress: data.market?.marketAddress, type: button2 } });
          }}
        >
          {button2}
        </ButtonPrimaryMedim>
      </div>
    </div>
  );
};

// const mock = [
//   {
//     market: undefined,
//     userSupplyStored: '',
//     userSupply: '',
//     userBorrowStored: '',
//     userBorrow: '',
//     isAssetIn: true,
//     walletBalance: '',
//   },
//   {
//     market: undefined,
//     userSupplyStored: '',
//     userSupply: '',
//     userBorrowStored: '',
//     userBorrow: '',
//     isAssetIn: true,
//     walletBalance: '',
//   },
//   {
//     market: undefined,
//     userSupplyStored: '',
//     userSupply: '',
//     userBorrowStored: '',
//     userBorrow: '',
//     isAssetIn: true,
//     walletBalance: '',
//   },
// ];

const Empty = () => {
  return (
    <div className="flex flex-col items-center py-16">
      <img className="h-16 object-fill" src={ImgEmpty} alt="empty" />
      <div className="mt-2 text-base font-bold text-white/50">No Data</div>
    </div>
  );
};

const Supply = () => {
  const list = useRecoilValue(accountSupplyMarketsState);

  return (
    <div>
      <div className="hidden w-full grid-cols-[15%_18%_18%_18%_12.5%_18.5%] px-6 py-2 text-sm text-[#fff]/50 lg:grid ">
        <div className="ml-16">Assets</div>
        <div>Supply APY</div>
        <div>Amount</div>
        <div>Wallet Balance</div>
        <div className="text-center">Collateral</div>
        <div className="ml-2">Action</div>
      </div>
      {list.length === 0 ? <Empty /> : list.map((item, index) => <Row data={item} type="Supply" key={index} />)}
    </div>
  );
};

const Borrow = () => {
  const list = useRecoilValue(accountBorrowMarketsState);

  return (
    <div>
      <div className="hidden w-full grid-cols-[15%_18%_18%_18%_12.5%_18.5%] px-6 py-2 text-sm text-[#fff]/50 lg:grid ">
        <div className="ml-16">Assets</div>
        <div>Borrow APY</div>
        <div>Amount</div>
        <div>Wallet Balance</div>
        <div className="text-center"></div>
        <div className="ml-2">Action</div>
      </div>
      {list.length === 0 ? <Empty /> : list.map((item, index) => <Row data={item} type="Borrow" key={index} />)}
    </div>
  );
};

const Tabs = () => {
  return (
    <Tab.List className="mb-4 flex space-x-12 border-b border-white/20 lg:mb-2.5">
      {['Supply', 'Borrow'].map((item, index) => (
        <Tab
          className={({ selected }) =>
            classnames(
              'py-4 text-[1.375rem] font-bold',
              selected ? 'border-b border-[#e921c3] text-white' : 'text-white/50  hover:text-white'
            )
          }
          key={index}
        >
          {item}
        </Tab>
      ))}
    </Tab.List>
  );
};

function Dashboard() {
  return (
    <div className="relative mx-auto flex flex-col items-center bg-transparent pt-px">
      <img className="fixed h-[53.5rem] object-fill md:w-full" src={bg2} alt="" />
      <p className="mt-8 bg-gradient-to-r from-[#e921c3] to-[#0067ff] bg-clip-text text-4xl font-bold text-transparent">
        Dashboard
      </p>
      <div className="relative mx-auto mt-2 w-full max-w-[74.5rem] px-4 sm:px-16 lg:px-8">
        <Overview />
        <div className="mt-4">
          <Tab.Group>
            <Tabs />
            <Tab.Panels>
              <Tab.Panel>
                <Supply />
              </Tab.Panel>
              <Tab.Panel>
                <Borrow />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
