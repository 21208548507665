import { ReactNode, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { chainIdState, connectActiveState } from '@/state/Wallet';
import { isSupportedChain, SUPPORTED_CHAIN } from '@/config/tools';

import ImgSwitchNetwork from '@/assets/common/switchNetwork.webp';
import ImgConnectWallet from '@/assets/common/connectWallet.webp';
import { ButtonPrimaryLarge } from '../Button';
import { useWallet } from '@/hooks/useWallet';
import UserDialog from '@/components/Dialog/UserDialog';

function Network({ children }: { children: ReactNode }) {
  const active = useRecoilValue(connectActiveState);
  const chainId = useRecoilValue(chainIdState);

  const [isOpen, setIsOpen] = useState(false);

  const { switchOrAddChain } = useWallet();

  const connectNetWork = async () => {
    setIsOpen(true);
  };

  const switchChain = async () => {
    try {
      await switchOrAddChain(SUPPORTED_CHAIN);
    } catch (err) {
      console.log(err);
    }
  };

  if (!!!active) {
    return (
      <div className="absolute top-1/3 flex w-full -translate-y-1/3 flex-col items-center">
        <img className="h-32 object-cover md:h-60" src={ImgConnectWallet} alt="" />
        <div className="mt-4 text-center text-xl font-bold text-white md:text-[2.5rem]">Please Connect Wallet</div>
        <ButtonPrimaryLarge className="mt-10" onClick={connectNetWork}>
          Connect
        </ButtonPrimaryLarge>
        <UserDialog setIsOpen={setIsOpen} isOpen={isOpen} />
      </div>
    );
  }

  if (isSupportedChain(chainId)) {
    return <>{children}</>;
  }
  return (
    <div className="absolute top-1/3 flex w-full -translate-y-1/3 flex-col items-center">
      <img className="h-32 object-cover md:h-60" src={ImgSwitchNetwork} alt="" />
      <div className="mt-4 text-center text-xl font-bold text-white md:text-[2.5rem]">
        Please switch to Evmos Mainnet
      </div>
      <ButtonPrimaryLarge className="mt-10" onClick={switchChain}>
        Switch
      </ButtonPrimaryLarge>
    </div>
  );
}

export default Network;
