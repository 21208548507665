import BigNumber from 'bignumber.js';

export const BLANK_CHAR = '-';
export const isBlankChar = (value: string) => value === BLANK_CHAR;

BigNumber.config({
  // DECIMAL_PLACES,
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
  EXPONENTIAL_AT: [-1e9, 1e9], // [-7, 20],
  // RANGE: [-1e9, 1e9],
  // CRYPTO: false,
  // MODULO_MODE: 1,
  POW_PRECISION: 18,
  // FORMAT: {
  //   // string to prepend
  //   prefix: '',
  //   // decimal separator
  //   decimalSeparator: '.',
  //   // grouping separator of the integer part
  //   groupSeparator: ',',
  //   // primary grouping size of the integer part
  //   groupSize: 3,
  //   // secondary grouping size of the integer part
  //   secondaryGroupSize: 0,
  //   // grouping separator of the fraction part
  //   fractionGroupSeparator: ' ',
  //   // grouping size of the fraction part
  //   fractionGroupSize: 0,
  //   // string to append
  //   suffix: '',
  // },
  // ALPHABET: '0123456789abcdefghijklmnopqrstuvwxyz',
});

export type BigNumberish = BigNumber | number | string | null | undefined;

export const isNumeric = (value: BigNumberish) =>
  value != null &&
  (BigNumber.isBigNumber(value) ||
    (typeof value === 'number' && !isNaN(value)) ||
    (typeof value === 'string' && /^[-+]?([0-9]+(\.[0-9]*)?|\.[0-9]+)([eE][-+]?[0-9]+)?$/.test(value.toString())));

export const isNumericString = (value: string) => isNumeric(value);

export function bigNumber(value: BigNumberish) {
  if (value == null || !isNumeric(value)) {
    return null;
  }
  return new BigNumber(value);
}

export function bigPlus(x: BigNumberish, y: BigNumberish) {
  const _x = bigNumber(x);
  const _y = bigNumber(y);
  if (_x == null || _y == null) {
    return null;
  }
  return _x.plus(_y).toString();
}

export function bigSub(x: BigNumberish, y: BigNumberish) {
  const _x = bigNumber(x);
  const _y = bigNumber(y);
  if (_x == null || _y == null) {
    return null;
  }
  return _x.minus(_y).toString();
}

export function bigMul(x: BigNumberish, y: BigNumberish) {
  const _x = bigNumber(x);
  const _y = bigNumber(y);
  if (_x == null || _y == null) {
    return null;
  }
  if (_x && _y) {
    return _x.times(_y).toString();
  }
}

export function bigDiv(x: BigNumberish, y: BigNumberish) {
  const _x = bigNumber(x);
  const _y = bigNumber(y);
  if (_x == null || _y == null) {
    return null;
  }
  return _x.div(_y).toString();
}

export function bigPow(x: BigNumberish, y: BigNumberish) {
  const _x = bigNumber(x);
  const _y = bigNumber(y);
  if (_x == null || _y == null) {
    return null;
  }
  return _x.pow(_y).toString();
}

export function bigGreaterThan(x: BigNumberish, y: BigNumberish, defaultValue = false) {
  const _x = bigNumber(x);
  const _y = bigNumber(y);
  if (_x == null || _y == null) {
    return defaultValue;
  }
  return _x.gt(_y);
}

export function bigGreaterThanOrEqualTo(x: BigNumberish, y: BigNumberish, defaultValue = false) {
  const _x = bigNumber(x);
  const _y = bigNumber(y);
  if (_x == null || _y == null) {
    return defaultValue;
  }
  return _x.gte(_y);
}

export function bigLessThan(x: BigNumberish, y: BigNumberish, defaultValue = false) {
  const _x = bigNumber(x);
  const _y = bigNumber(y);
  if (_x == null || _y == null) {
    return defaultValue;
  }
  return _x.lt(_y);
}

export function bigLessThanOrEqualTo(x: BigNumberish, y: BigNumberish, defaultValue = false) {
  const _x = bigNumber(x);
  const _y = bigNumber(y);
  if (_x == null || _y == null) {
    return defaultValue;
  }
  return _x.lte(_y);
}

export function bigMax<T extends BigNumberish>(x: T, y: T): T {
  const _x = bigNumber(x);
  const _y = bigNumber(y);
  if (_x == null) {
    return y;
  }
  if (_y == null) {
    return x;
  }
  return _x.gt(_y) ? x : y;
}

export function bigMin<T extends BigNumberish>(x: T, y: T): T {
  const _x = bigNumber(x);
  const _y = bigNumber(y);
  if (_x == null) {
    return x;
  }
  if (_y == null) {
    return y;
  }
  return _x.lt(_y) ? x : y;
}

export function bigRangeIn(
  num: BigNumberish,
  lower: BigNumberish,
  upper: BigNumberish,
  bounds: string[] = ['lower', 'upper']
) {
  if (bigLessThanOrEqualTo(upper, lower)) {
    return false;
  }

  return (
    (bounds.includes('lower') ? bigGreaterThanOrEqualTo : bigGreaterThan)(num, lower) &&
    (bounds.includes('upper') ? bigLessThanOrEqualTo : bigLessThan)(num, upper)
  );
}
