import { BigNumber, ContractFunction } from 'ethers';
import { calcGasLimit } from '@/config/tools';

export type EstimateGas = { [name: string]: ContractFunction<BigNumber> };

export function mapEstimateGas(estimateGas: EstimateGas): EstimateGas {
  const _estimateGas: EstimateGas = {};
  for (const key in estimateGas) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _estimateGas[key] = async (...args: Array<any>) => {
      const method = estimateGas[key];
      const gas = await method(...args);
      return calcGasLimit(gas);
    };
  }
  return _estimateGas;
}
