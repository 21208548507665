import classNames from 'classnames';
import style from './index.module.css';

export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

export interface ButtonProps {
  children: React.ReactNode;
  className?: string;
  type?: ButtonType;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  modalToggle?: string;
}

export function Button(props: ButtonProps) {
  const _props = { ...props };
  _props.className = classNames('rounded-sm font-bold', props.className, props.loading ? style.loadingbtn : '');
  return (
    <button
      className={_props.className}
      type={props.type ?? ButtonType.BUTTON}
      disabled={props.disabled ?? false}
      onClick={props.onClick}
      data-modal-toggle={props.modalToggle}
    >
      {props.children}
    </button>
  );
}

const large = 'h-12 px-4';
const medim = 'h-10 px-6';
const small = 'h-[1.875rem] px-4';

const primary =
  'text-base text-white hover:text-white active:text-white bg-gradient-to-r from-[#e921c3] to-[#0067ff] hover:from-[#ef64d5] hover:to-[#4d94ff] active:from-[#c31ba3] active:to-[#0056d6] disabled:opacity-40 disabled:hover:text-white disabled:hover:from-[#e921c3] disabled:hover:to-[#0067ff] disabled:cursor-not-allowed';

const outline =
  'text-base text-[#e921c3] md:hover:text-white active:text-white border border-[#e921c3] bg-transparent hover:boder-none active:boder-none md:active:bg-[#c31ba3] disabled:opacity-40 disabled:hover:text-[#e921c3] disabled:hover:border disabled:hover:border-[#e921c3] disabled:hover:bg-transparent disabled:cursor-not-allowed';

const danger = 'text-base text-white bg-[#e63757] disabled:opacity-40';

export function ButtonPrimaryLarge(props: ButtonProps) {
  const _props = { ...props };
  _props.className = classNames(large, primary, props.className);
  return <Button {..._props} />;
}

export function ButtonPrimaryMedim(props: ButtonProps) {
  const _props = { ...props };
  _props.className = classNames(medim, primary, props.className);
  return <Button {..._props} />;
}

export function ButtonPrimarySmall(props: ButtonProps) {
  const _props = { ...props };
  _props.className = classNames(small, primary, props.className);
  return <Button {..._props} />;
}

export function ButtonOutlineLarge(props: ButtonProps) {
  const _props = { ...props };
  _props.className = classNames(large, props.className, outline);
  return <Button {..._props} />;
}

export function ButtonOutlineMedim(props: ButtonProps) {
  const _props = { ...props };
  _props.className = classNames(medim, outline, props.className);
  return <Button {..._props} />;
}

export function ButtonOutlineSmall(props: ButtonProps) {
  const _props = { ...props };
  _props.className = classNames(small, outline, props.className);
  return <Button {..._props} />;
}

export function ButtonDangerLarge(props: ButtonProps) {
  const _props = { ...props };
  _props.className = classNames(large, danger, props.className);
  return <Button {..._props} />;
}
