import { createContext, ReactNode, useCallback, useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formatUnits } from '@ethersproject/units';

import { Claim, ABI } from '@/contracts/Claim';
import { getContracts } from '@/config/contracts';
import { calcGasLimit } from '@/config/tools';
import { accountAddressState, accountPendingClaimState } from '@/state/Wallet';
import { useContract } from './useContract';
import { useInterval } from '@/utils/useInterval';
import { hasString } from '@/utils/helper';

type ClaimContext = {
  pendingAll: () => Promise<void>;
};

const UseClaimContext = createContext<ClaimContext | null>(null);

function ClaimProvider({ children }: { children: ReactNode }) {
  const address = useRecoilValue(accountAddressState);

  const setPendingClaim = useSetRecoilState(accountPendingClaimState);

  const { signerContract } = useContract();
  const contract = getContracts();
  const claim = signerContract<Claim>(contract.claim, ABI);

  const pendingAll = useCallback(async () => {
    try {
      if (claim == null) {
        return;
      }
      if (!hasString(address)) {
        return;
      }
      const list = await claim.pendingAll(address);
      const pending = list?.map((item) => formatUnits(item, 18)) ?? [];
      if (pending.length === 4) {
        setPendingClaim({
          farmIPending: pending[0],
          farmIIPending: pending[1],
          pendingDistributionI: pending[2],
          pendingDistributionII: pending[3],
        });
      }
    } catch (err) {
      console.log(err);
    }
  }, [address, claim, setPendingClaim]);

  useInterval(pendingAll);

  return <UseClaimContext.Provider value={{ pendingAll }}>{children}</UseClaimContext.Provider>;
}

function useClaim() {
  const context = useContext(UseClaimContext);
  if (context === null) {
    throw new Error('useClaim() can only be used inside of <ClaimProvider />, please declare it at a higher level.');
  }
  const { signerContract } = useContract();
  const { claim } = getContracts();

  const claimDistribution = async (address: string) => {
    const contract = signerContract<Claim>(claim, ABI);
    if (contract == null) {
      return null;
    }
    const gasLimit = await contract.estimateGas.claimDistribution(address);
    const overrides = {
      gasLimit: calcGasLimit(gasLimit, 50),
    };

    const result = await contract.claimDistribution(address, overrides);

    return result;
  };

  return { ...context, claimDistribution };
}

export { useClaim, ClaimProvider };
