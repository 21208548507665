import { BigNumberish, BigNumber, Contract, Signer, utils } from 'ethers';

import ERC20_ABI_json from './abis/ERC20.json';
import { mapEstimateGas, EstimateGas } from './tool';

export class LpTokenContract {
  private contract: Contract;
  private isEther: boolean;
  private estimateGas: EstimateGas;

  constructor(address: string, isEther: boolean, signer?: Signer) {
    this.isEther = isEther;
    this.contract = new Contract(address, isEther ? '[]' : ERC20_ABI_json, signer);
    this.estimateGas = mapEstimateGas(this.contract.estimateGas);
  }

  async decimals(): Promise<BigNumberish> {
    if (this.isEther) {
      return 18;
    }
    return this.contract.decimals();
  }

  async balance(address: string) {
    const decimals = await this.decimals();
    let balance: BigNumber;
    if (this.isEther) {
      balance = await this.contract.signer.getBalance('latest');
    } else {
      balance = await this.contract.balanceOf(address);
    }
    return utils.formatUnits(balance, decimals);
  }
}
