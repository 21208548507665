// import { useState } from 'react';
// import ClassNames from 'classnames';

import bg1 from '@/assets/about/bg1.webp';
import bg2 from '@/assets/about/bg2.webp';

import feature1 from '@/assets/about/feature1.png';
import feature2 from '@/assets/about/feature2.png';
import feature3 from '@/assets/about/feature3.png';

import security from '@/assets/about/security.webp';
import security1 from '@/assets/about/security1.png';
import security2 from '@/assets/about/security2.png';
import security3 from '@/assets/about/security3.png';
import security4 from '@/assets/about/security4.png';
import { getAssetImage } from '@/utils/helper';

import style from './index.module.css';

const Features = () => {
  const features = [
    { title: '01', content: 'Connect the Ethereum and Cosmos ecosystems', img: feature1 },
    { title: '02', content: 'Governed by the community, fair distribution all for $CLND holders', img: feature2 },
    { title: '03', content: 'Isolate the lending risk of mainstream assets and long-tail assets', img: feature3 },
  ];
  return (
    <div className="px-4 pt-10 md:px-8 md:pt-[5.5rem]">
      <div className="mx-auto max-w-[70.5rem] text-center text-5xl leading-[3.5rem] lg:text-left lg:text-[5rem] lg:leading-[6rem]">
        <p className="font-bold text-white">Features</p>
        <div className="mt-[2.5rem] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {features.map((feature, index) => {
            return (
              <div className={style.border} key={index}>
                <p className="text-left text-[2rem] leading-[2rem]">
                  <span className="bg-gradient-to-r from-[#e921c3] to-[#0067ff] bg-clip-text text-transparent">
                    {feature.title}
                  </span>
                </p>
                <p className="mt-4 flex-auto whitespace-pre-wrap text-left text-base text-white">{feature.content}</p>
                <img className="h-36 w-60" src={feature.img} alt="" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Securities = () => {
  const securities = [
    { img: security1, content: 'Forked from Compound' },
    { img: security2, content: 'Timelock contract with a two-day delay' },
    { img: security3, content: 'All $CLND will be released linearly' },
    { img: security4, content: 'Multi-signature management' },
  ];
  return (
    <div className="relative mb-[5.75rem] flex h-[53.5rem] justify-center lg:mb-0">
      <img className="absolute h-[53.5rem] object-fill md:w-full" src={bg2} alt="" />
      <div className="z-20 mt-[6.5rem] hidden w-full items-center justify-center lg:visible lg:flex xl:w-[70.5rem]">
        <img className="h-[19rem] w-[21.438rem] xl:h-[28.5rem] xl:w-[32.063rem]" src={security} alt="" />
        <div className="ml-10 lg:ml-20">
          <p className="text-[5rem] font-bold leading-[6rem] text-white">Security</p>
          <div className="mt-10">
            {securities.map((security, index) => {
              return (
                <div className="mb-8 flex items-center last:mb-0" key={index}>
                  <img className="h-[3.5rem] w-[3.5rem]" src={security.img} alt="" />
                  <p className="ml-4 text-2xl font-bold leading-8 text-white">{security.content}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="visible z-20 mt-[7rem] lg:hidden">
        <p className="text-center text-5xl font-bold leading-[3.5rem] text-white">Security</p>
        <img className="mx-auto mt-6 h-[19rem] w-[21.438rem]" src={security} alt="" />
        <div className="mt-10 px-4">
          {securities.map((security, index) => {
            return (
              <div className="mb-8 flex items-center last:mb-0" key={index}>
                <img className="h-[3.5rem] w-[3.5rem]" src={security.img} alt="" />
                <p className="ml-4 break-all text-2xl font-bold leading-[1.625rem] text-white">{security.content}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Partners = () => {
  const primaryContext = require.context('@/assets/partners/primary', true, /.png$/);
  const secondaryContext = require.context('@/assets/partners/secondary', true, /.png$/);
  const primary = getAssetImage(primaryContext);
  const secondary = getAssetImage(secondaryContext);

  const list = Array.from(primary.keys())
    .sort()
    .map((item) => primary.get(item))
    .concat(
      Array.from(secondary.keys())
        .sort()
        .map((item) => secondary.get(item))
    );

  return (
    <div className="px-4 md:px-8">
      <div className="mx-auto mb-20 max-w-[70.5rem] bg-black lg:mb-40">
        <div className="my-10 text-center text-5xl font-bold leading-[3.5rem] text-white lg:text-left lg:text-[5rem] lg:leading-[6rem]">
          Partners
        </div>
        <div className={style.partner}>
          {list.map((item, index) => (
            <img src={item} alt="" key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

function About() {
  return (
    <div>
      <div className="relative -top-20 -mb-20 h-[36.375rem] md:-top-24 md:-mb-24 md:h-auto md:pb-[43.75%]">
        <img className="absolute h-[36.375rem] object-cover md:h-auto md:w-full" src={bg1} alt="" />
        <p className="absolute bottom-[4.5rem] w-full whitespace-pre-wrap text-center text-[2rem] font-bold leading-[2.8125rem] text-white md:bottom-[2.8rem] lg:bottom-[3.5rem] lg:text-[3rem] lg:leading-[4.125rem] xl:bottom-[5.5rem] xl:text-[4rem] 2xl:bottom-[7.5rem]">
          {`The Money Market of Cosmos Ecosystem`}
        </p>
      </div>
      <Features />
      <Securities />
      <Partners />
    </div>
  );
}

export default About;
