import twitter from '@/assets/common/twitter.png';
import discord from '@/assets/common/discord.png';
import medium from '@/assets/common/medium.png';
import github from '@/assets/common/github.png';

function Footer() {
  const medias = [
    { img: twitter, link: 'https://twitter.com/coslend' },
    { img: discord, link: 'https://discord.gg/3ykeh6cXrT' },
    { img: medium, link: 'https://medium.com/@coslend' },
    { img: github, link: 'https://github.com/coslendteam' },
  ];
  return (
    <footer className="z-50 px-4 pt-12 pb-10 text-sm text-white md:px-8 md:py-20">
      <div className="mx-auto max-w-[70.5rem]">
        <div className="flex flex-wrap justify-between gap-y-10">
          <div className="mr-12 flex space-x-6 md:space-x-10">
            {medias.map((media, index) => {
              return (
                <a href={media.link} target="_blank" rel="nofollow noopener noreferrer" key={index}>
                  <img className="h-6 w-6" src={media.img} alt="media" />
                </a>
              );
            })}
          </div>
          <p>2022 All right reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
