import classNames from 'classnames';
import { Fragment, useCallback, useRef } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Notify } from '@/components/Notify';
import { ellipsizeText } from '@/utils/input';

import Metamask from '@/assets/common/metamask.png';
import WalletConnect from '@/assets/common/walletconnect.png';
import Circle from '@/assets/common/circle.png';
import Disconnect from '@/assets/common/disconnect.png';
import ImgCopy from '@/assets/common/copy.png';
import { useWallet } from '@/hooks/useWallet';
import { hasString } from '@/utils/helper';

export interface DialogProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  address?: string;
}

function UserDialog(props: DialogProps) {
  const closeModal = () => {
    props.setIsOpen(false);
  };

  const { connect, disconnect } = useWallet();

  const refDiv = useRef(null);

  const handleCopy = useCallback(
    (text, result) => hasString(text) && result && Notify.success('Copied successfully'),
    []
  );

  return (
    <div ref={refDiv}>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30 overflow-y-auto bg-[#0056D6]/20 backdrop-blur-sm backdrop-filter"
          onClose={closeModal}
          initialFocus={refDiv}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative z-30 my-8 inline-block min-h-[10.3rem] w-full max-w-[20rem] transform overflow-hidden rounded-lg border-[0.06rem] border-[#FFFFFF]/10 bg-[#131415] p-6 text-left align-middle shadow-xl transition-all md:max-w-[30rem]">
                <div
                  className="absolute right-[0.8rem] top-[0.5rem] text-[1.3rem] font-normal leading-[1.3rem] text-[#fff] hover:cursor-pointer"
                  onClick={() => {
                    props.setIsOpen(false);
                  }}
                >
                  x
                </div>
                <Dialog.Title as="h3" className="text-lg text-[1.38rem] font-medium leading-6 text-[#fff]">
                  {props.address ? 'Account' : 'Connect Wallet'}
                </Dialog.Title>
                <div className="mt-[1.5rem]">
                  {props.address ? (
                    <p className="flex h-[3.88rem] items-center bg-[#202122] px-6 text-[1rem] text-[#fff]">
                      <img className="mr-[1rem] h-[1rem] w-[1rem]" src={Circle} alt="" />
                      {ellipsizeText(props.address, 16, 'middle')}
                      <div className="flex-1" />
                      <CopyToClipboard text={props.address} onCopy={handleCopy}>
                        <img className="h-6 w-6 cursor-pointer" src={ImgCopy} alt="copy" />
                      </CopyToClipboard>
                    </p>
                  ) : (
                    <>
                      <p
                        className="flex h-[3.88rem] items-center bg-[#202122] pl-[1rem] text-[1rem] text-[#fff] hover:cursor-pointer"
                        onClick={() => {
                          try {
                            connect('MetaMask');
                          } catch (err) {
                            console.log(err);
                          }
                          props.setIsOpen(false);
                        }}
                      >
                        <img className="mr-[1rem] h-[2rem] w-[2rem]" src={Metamask} alt="" />
                        MetaMask
                      </p>
                      <p
                        className="mt-2 flex h-[3.88rem] items-center bg-[#202122] pl-[1rem] text-[1rem] text-[#fff] hover:cursor-pointer"
                        onClick={() => {
                          try {
                            connect('WalletConnect');
                          } catch (err) {
                            console.log(err);
                          }
                          props.setIsOpen(false);
                        }}
                      >
                        <img className="mr-[1rem] h-[2rem] w-[2rem]" src={WalletConnect} alt="" />
                        WalletConnect
                      </p>
                    </>
                  )}
                  <div
                    className={classNames(
                      'mt-[1rem] flex w-max items-center text-[0.88rem] text-[#fff]/50 hover:cursor-pointer',
                      props.address ? 'visible' : 'hidden'
                    )}
                    onClick={() => {
                      disconnect();
                      props.setIsOpen(false);
                    }}
                  >
                    <img className="mr-[0.5rem] h-[1.25rem] w-[1.25rem]" src={Disconnect} alt="" />
                    Disconnect
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
export default UserDialog;
