import classNames from 'classnames';
import { useRecoilValue } from 'recoil';
import { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import UserDialog from '@/components/Dialog/UserDialog';
import { ButtonPrimaryLarge, ButtonOutlineLarge, ButtonDangerLarge } from '@/components/Button';
import { ellipsizeText } from '@/utils/input';
import { accountAddressState, chainIdState, connectActiveState } from '@/state/Wallet';

import menu from '@/assets/common/menu.png';

import logo from '@/assets/logo.png';
import { isSupportedChain, SUPPORTED_CHAIN } from '@/config/tools';
import { useWallet } from '@/hooks/useWallet';
import { Menu } from '@headlessui/react';

import styles from './index.module.css';

function Header() {
  const navs = [
    // { name: 'Pools', link: '/pools' },
    { name: 'Markets', link: '/markets' },
    { name: 'Dashboard', link: '/dashboard' },
  ];

  const bridges = [{ name: 'Nomad', link: 'https://app.nomad.xyz/' }];

  const [isOpen, setIsOpen] = useState(false);

  const visibleRef = useRef(false);
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    visibleRef.current = !visibleRef.current;
    setVisible(visibleRef.current);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const { switchOrAddChain } = useWallet();

  const isHome = location.pathname === '/';

  const started = () => {
    // navigate('/pools');
    navigate('/markets');
  };

  const chainId = useRecoilValue(chainIdState);
  const address = useRecoilValue(accountAddressState);
  const connectActive = useRecoilValue(connectActiveState);

  const switchChain = async () => {
    try {
      await switchOrAddChain(SUPPORTED_CHAIN);
    } catch (err) {
      console.log(err);
    }
  };

  const onClickToWeb3 = () => {
    setIsOpen(true);
  };

  const externalLink = (link: string) => {
    window.open(link);
    setVisible(false);
  };

  const locationRef = useRef<string>();
  useEffect(() => {
    if (location.pathname !== locationRef.current) {
      setVisible(false);
    }
    locationRef.current = location.pathname;
  }, [location]);

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset > 255 ? 255 : window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const style = {
    backgroundColor: `rgba(0, 0, 0, ${offset})`,
  };

  return (
    <header className="fixed left-0 right-0 z-50 bg-transparent text-base font-bold md:px-8 md:py-6" style={style}>
      <div className="relative mx-auto max-w-[70.5rem] select-none">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex h-20 items-center md:h-12">
            <img className="mx-4 h-6 md:hidden" src={menu} alt="menu" onClick={handleClick} />
            <img className="h-10 cursor-pointer" src={logo} alt="logo" onClick={() => navigate('/')} />
          </div>
          <div
            className={classNames(
              visible ? 'visible h-auto max-h-96 py-10' : '-mb-px hidden h-auto border-t-0 py-0 md:flex',
              'flex flex-auto flex-wrap items-center justify-end gap-y-6 bg-[#1f1f1f] md:mr-[9.875rem] md:h-auto md:space-x-6 md:border-none md:bg-transparent md:p-0 md:transition-none'
            )}
          >
            {navs.map((nav, index) => {
              return (
                <NavLink
                  className={({ isActive }) =>
                    classNames(
                      isActive ? 'text-white' : 'text-white/50',
                      'w-screen px-4 hover:text-white md:w-auto md:px-0'
                    )
                  }
                  to={nav.link}
                  key={index}
                >
                  {nav.name}
                </NavLink>
              );
            })}
            <Menu as="div" className="relative">
              <Menu.Button as="div" className={styles.bridge}>
                Bridges
                <div />
              </Menu.Button>
              <Menu.Items className="absolute top-8 left-4 z-50 !ml-0 flex min-w-[8rem] flex-col rounded-lg border border-white/10 bg-[#1f1f1f] p-6 text-base font-bold text-white outline-none md:left-1/2 md:-translate-x-1/2">
                {bridges.map((item, index) => (
                  <Menu.Item key={index}>
                    <a className="mb-4 last:mb-0" href={item.link} target="_blank" rel="nofollow noopener noreferrer">
                      {item.name}
                    </a>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>
            <div
              className="w-screen cursor-pointer px-4 text-white/50 hover:text-white md:w-auto md:px-0"
              onClick={() => externalLink('https://docs.coslend.com')}
            >
              Docs
            </div>
          </div>
          {isHome ? (
            <ButtonPrimaryLarge className="absolute top-4 right-4 md:top-0 md:right-0" onClick={started}>
              Get started
            </ButtonPrimaryLarge>
          ) : connectActive ? (
            isSupportedChain(chainId) ? (
              <ButtonOutlineLarge className="absolute top-4 right-4 md:top-0 md:right-0" onClick={onClickToWeb3}>
                {ellipsizeText(address, 9, 'middle')}
              </ButtonOutlineLarge>
            ) : (
              <ButtonDangerLarge className="absolute top-4 right-4 md:top-0 md:right-0" onClick={switchChain}>
                {'Wrong Network'}
              </ButtonDangerLarge>
            )
          ) : (
            <ButtonPrimaryLarge className="absolute top-4 right-4 md:top-0 md:right-0" onClick={onClickToWeb3}>
              Connect Wallet
            </ButtonPrimaryLarge>
          )}
        </div>
      </div>
      <UserDialog setIsOpen={setIsOpen} isOpen={isOpen} address={address} />
    </header>
  );
}

export default Header;
