import React from 'react';
import { useRecoilState } from 'recoil';
import { ContractReceipt, ContractTransaction } from 'ethers';

import { AccountMarket } from '@/contracts/Lens';
import { CTokenContract } from '@/contracts';

import {
  bigDiv,
  bigGreaterThan,
  bigGreaterThanOrEqualTo,
  bigLessThan,
  bigMax,
  bigMin,
  bigMul,
  bigRangeIn,
  bigSub,
} from '@/utils/operation';

import useLoading from './useLoading';
import useCTokenContract from './useCTokenContract';

import { accountOverviewState } from '@/state/Wallet';
import { formatNumber4Loans } from '@/utils/format';
import { useDashboard } from './useDashboard';

type OnSuccess = (tx: ContractReceipt) => void;
type OnError = (error: unknown) => void;
interface AccountCallProps {
  market: AccountMarket;
  avilable?: string;
  maxAmount: () => string;
  maxInputAmount: () => string;
  contractCall: (cToken: CTokenContract, amount: string) => Promise<ContractTransaction>;
}

export interface SubmitProps {
  onSuccess?: OnSuccess;
  onError?: OnError;
}

export interface AccountCallResponse {
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  onMax: () => void;
  avilable: string;
  loading: boolean;
  disabled: boolean;
  onSubmit: (props: SubmitProps) => Promise<void>;
  isApproved: boolean;
}

export const BORROWCAP_MAX_SCALE = 0.9;

export function useAccountCall(props: AccountCallProps): AccountCallResponse {
  const { market, avilable, maxAmount, maxInputAmount, contractCall } = props;

  const [amount, setAmount] = React.useState('');

  const { loading, loadingWith } = useLoading();
  const CToken = useCTokenContract(market.market);
  const { getAll } = useDashboard();

  const onMax = React.useCallback(
    () => setAmount(formatNumber4Loans(maxAmount(), { places: market.market?.underlyingDecimals })),
    [maxAmount, market.market?.underlyingDecimals]
  );

  const onSubmit = (_props: SubmitProps) =>
    loadingWith(async () => {
      try {
        const tx = await contractCall(CToken, amount);
        const receipt = await tx.wait(1);
        getAll();
        if (_props.onSuccess) {
          _props.onSuccess(receipt);
        }
      } catch (error) {
        if (_props.onError) {
          _props.onError(error);
        }
      }
    });

  const _avilable = formatNumber4Loans(avilable || maxAmount(), { places: market.market?.underlyingDecimals });

  const validAmount = formatNumber4Loans(maxInputAmount(), { places: market.market?.underlyingDecimals });

  return {
    amount,
    setAmount,
    onMax,
    avilable: _avilable,
    loading,
    disabled: !bigRangeIn(amount, 0, validAmount, ['upper']),
    onSubmit,
    isApproved: bigGreaterThanOrEqualTo(market.allowance, amount),
  };
}

export function useDeposit(market: AccountMarket) {
  const maxAmount = React.useCallback(() => market.walletBalance, [market.walletBalance]);

  const contractCall = React.useCallback(
    (cToken: CTokenContract, amount: string) =>
      bigGreaterThanOrEqualTo(market.allowance, amount) ? cToken.mint(amount) : cToken.underlyingApprove(),
    [market.allowance]
  );

  return useAccountCall({ market, maxAmount, maxInputAmount: maxAmount, contractCall });
}

export function useWithdraw(market: AccountMarket) {
  const { borrowCap, userTotalBorrow } = useRecoilState(accountOverviewState)[0];
  const { userSupply, isAssetIn } = market;
  const { price, collateralFactorMantissa } = market.market ?? {};

  const maxAmount = React.useCallback(() => {
    if (isAssetIn && bigGreaterThan(collateralFactorMantissa, 0) && bigGreaterThan(userTotalBorrow, 0)) {
      const suppliable = bigDiv(
        bigSub(borrowCap, bigDiv(userTotalBorrow, BORROWCAP_MAX_SCALE)),
        bigMul(price, collateralFactorMantissa)
      );
      return bigMin(userSupply, bigMax(suppliable, '0')) ?? '0';
    } else {
      return userSupply;
    }
  }, [borrowCap, userTotalBorrow, userSupply, isAssetIn, price, collateralFactorMantissa]);

  const maxInputAmount = React.useCallback(() => {
    if (isAssetIn && bigGreaterThan(collateralFactorMantissa, 0) && bigGreaterThan(userTotalBorrow, 0)) {
      const suppliable = bigDiv(bigSub(borrowCap, userTotalBorrow), bigMul(price, collateralFactorMantissa));
      return bigMin(userSupply, bigMax(suppliable, '0')) ?? '0';
    } else {
      return userSupply;
    }
  }, [borrowCap, userTotalBorrow, userSupply, isAssetIn, price, collateralFactorMantissa]);

  const contractCall = React.useCallback(
    (cToken: CTokenContract, amount: string) =>
      bigLessThan(amount, userSupply) ? cToken.redeem(amount) : cToken.redeemAll(),
    [userSupply]
  );

  return useAccountCall({ market, maxAmount, maxInputAmount, contractCall });
}

export function useBorrow(market: AccountMarket) {
  const price = market.market?.price;
  const { borrowCap, userTotalBorrow } = useRecoilState(accountOverviewState)[0];

  const maxAmount = React.useCallback(
    () => bigDiv(bigMax(bigSub(bigMul(borrowCap, BORROWCAP_MAX_SCALE), userTotalBorrow), '0'), price) ?? '0',
    [borrowCap, userTotalBorrow, price]
  );

  const maxInputAmount = React.useCallback(
    () => bigDiv(bigMax(bigSub(borrowCap, userTotalBorrow), '0'), price) ?? '0',
    [borrowCap, userTotalBorrow, price]
  );

  const contractCall = React.useCallback((cToken: CTokenContract, amount: string) => cToken.borrow(amount), []);

  return useAccountCall({ market, maxAmount, maxInputAmount, contractCall });
}

export function useRepay(market: AccountMarket) {
  const { allowance, userBorrow, walletBalance } = market;

  const maxAmount = React.useCallback(() => bigMin(userBorrow, walletBalance), [userBorrow, walletBalance]);

  const contractCall = React.useCallback(
    (cToken: CTokenContract, amount: string) =>
      bigGreaterThanOrEqualTo(allowance, amount)
        ? cToken.repayBorrow(amount, bigGreaterThanOrEqualTo(amount, userBorrow))
        : cToken.underlyingApprove(),
    [allowance, userBorrow]
  );

  return useAccountCall({ market, avilable: walletBalance, maxAmount, maxInputAmount: maxAmount, contractCall });
}
