/* eslint-disable @typescript-eslint/no-explicit-any */
import { Contract, Overrides } from 'ethers';
import { TransactionResponse } from '@ethersproject/providers';
import ABIComptroller from './abis/Comptroller.json';

export interface Comptroller extends Contract {
  enterMarkets: (address: string[], overrides?: Overrides) => Promise<TransactionResponse>;
  exitMarket: (address: string, overrides?: Overrides) => Promise<TransactionResponse>;
}

export const ABI = ABIComptroller;
