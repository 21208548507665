import { createContext, ReactNode, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { BigNumber as BN } from 'ethers';
import { formatUnits } from '@ethersproject/units';

import { getContracts } from '@/config/contracts';
import ABILens from '@/contracts/abis/Lens.json';
import { Lens, Market, MarketInternal, MintSpeedInternal, RateModelInternal } from '@/contracts/Lens';
import { marketsState, Overview, overviewState } from '@/state/Wallet';
import { useContract } from './useContract';
import { bigDiv, bigMul, bigPlus, bigPow, bigSub } from '@/utils/operation';
import { useInterval } from '@/utils/useInterval';

type MarketContext = null;

const UseMarketsContext = createContext<MarketContext | null>(null);

function MarketsProvider({ children }: { children: ReactNode }) {
  const { signerContract } = useContract();
  const contracts = getContracts();

  const setOverview = useSetRecoilState<Overview>(overviewState);

  const setMarkets = useSetRecoilState<Market[]>(marketsState);

  const lens = signerContract<Lens>(contracts.lens, ABILens);

  const getAll = useCallback(async () => {
    try {
      if (lens == null) {
        return;
      }
      const [metaData, rateModels, mintSpeeds] = await lens?.callStatic?.all();

      const rateModel = (market: string) => (rateModels as RateModelInternal[]).find((item) => item.market === market);
      const mintSpeed = (market: string) => (mintSpeeds as MintSpeedInternal[]).find((item) => item.market === market);

      let marketsSupply = '0';
      let marketsBorrow = '0';

      const list = (metaData as MarketInternal[])?.map((item) => {
        const mantissa = BN.from(18);
        const marketAddress = item.marketAddress;
        const marketDecimals = item.marketDecimals.toNumber();
        const marketSymbol = item.marketSymbol;
        const marketName = item.marketName;
        const underlyingAddress = item.underlyingAddress;
        const underlyingDecimals = item.underlyingDecimals.toNumber();
        const underlyingSymbol = item.underlyingSymbol;
        const underlyingName = item.underlyingName;

        const supplyRatePerSecond = formatUnits(item.supplyRatePerSecond.toString(), mantissa);
        const borrowRatePerSecond = formatUnits(item.borrowRatePerSecond.toString(), mantissa);

        const reserveFactorMantissa = formatUnits(item.reserveFactorMantissa, mantissa);
        const collateralFactorMantissa = formatUnits(item.collateralFactorMantissa, mantissa);

        const totalBorrows = formatUnits(item.totalBorrows, item.underlyingDecimals);
        const totalReserves = formatUnits(item.totalReserves, item.underlyingDecimals);

        const exchangeRateMantissa = mantissa.add(item.underlyingDecimals).sub(item.marketDecimals).toNumber();
        const exchangeRateCurrent = formatUnits(item.exchangeRateCurrent, exchangeRateMantissa);
        const totalSupply = formatUnits(item.totalSupply, item.marketDecimals);
        const totalSupplyUnderlying = bigMul(totalSupply, exchangeRateCurrent);

        const totalCash = formatUnits(item.totalCash, item.underlyingDecimals);

        const price = formatUnits(item.price, mantissa.add(mantissa).sub(item.underlyingDecimals));

        const isListed = item.isListed;

        const blockTime = item.blockTime.toString();
        const accrualBlockTime = item.accrualBlockTime.toString();
        const borrowIndex = item.borrowIndex.toString();
        const totalSupplyValue = bigMul(totalSupplyUnderlying, price);
        const totalBorrowValue = bigMul(totalBorrows, price);

        marketsSupply = bigPlus(marketsSupply, totalSupplyValue) ?? marketsSupply;
        marketsBorrow = bigPlus(marketsBorrow, totalBorrowValue) ?? marketsBorrow;

        const rate = rateModel(marketAddress);

        const speed = mintSpeed(marketAddress);
        const seconds = 365 * 24 * 60 * 60;

        const supplyApy = bigSub(bigPow(bigPlus('1', supplyRatePerSecond), seconds), '1');
        const borrowApy = bigSub(bigPow(bigPlus('1', borrowRatePerSecond), seconds), '1');

        let supplyMineApy;
        let borrowMineApy;
        const clndPrice = '1';
        if (speed) {
          const supplySpeed = formatUnits(speed.supplySpeed, mantissa);
          const borrowSpeed = formatUnits(speed.borrowSpeed, mantissa);
          supplyMineApy = bigDiv(bigMul(bigMul(supplySpeed, seconds), clndPrice), totalSupplyValue);
          borrowMineApy = bigDiv(bigMul(bigMul(borrowSpeed, seconds), clndPrice), totalBorrowValue);
        }
        return {
          marketAddress,
          marketDecimals,
          marketSymbol,
          marketName,
          underlyingAddress,
          underlyingDecimals,
          underlyingSymbol,
          underlyingName,
          exchangeRateCurrent,
          supplyRatePerSecond,
          borrowRatePerSecond,
          reserveFactorMantissa,
          collateralFactorMantissa,
          totalBorrows,
          totalReserves,
          totalSupply: totalSupplyUnderlying ?? '',
          totalCash,
          price,
          isListed,
          blockTime,
          accrualBlockTime,
          borrowIndex,
          totalSupplyValue: totalSupplyValue ?? '',
          totalBorrowValue: totalBorrowValue ?? '',
          supplyApy: supplyApy ?? '',
          borrowApy: borrowApy ?? '',
          supplyMineApy: supplyMineApy ?? '',
          borrowMineApy: borrowMineApy ?? '',
        };
      });

      // console.log(list);

      setMarkets(list ?? []);
      setOverview({ totalSupply: marketsSupply, totalBorrow: marketsBorrow });
    } catch (err) {
      console.log(err);
    }
  }, [lens, setMarkets, setOverview]);

  useInterval(getAll);

  return <UseMarketsContext.Provider value={null}>{children}</UseMarketsContext.Provider>;
}

// function useMarkets() {
//   // const context = useContext(UseMarketsContext);
//   // if (context === null) {
//   //   throw new Error(
//   //     'useMarkets() can only be used inside of <MarketsProvider />, please declare it at a higher level.'
//   //   );
//   // }
//   // return useMemo(() => {
//   //   return { ...context };
//   // }, [context]);
// }

export { MarketsProvider };
