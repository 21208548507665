import SuccessImg from '@/assets/common/success.png';
import WarningImg from '@/assets/common/warning.png';
import ErrorImg from '@/assets/common/error.png';
import ComingImg from '@/assets/common/coming.png';

import styles from './index.module.css';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Noty = require('@ppzp/noty/noty.js').default;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Animation = require('@ppzp/noty/animation/index.js').default;

const noty = Noty({
  rootClass: `${styles.noty}`,
  customPosition: true,
  duration: 3000,
  Animation: Animation,
  Content: {
    // info(content: string) {
    //   const el = document.createElement('div');
    //   el.innerHTML = `
    //    <div class="${styles.container}">
    //     <img class="${styles.icon}" src={${SuccessImg}}/>
    //     <div class="${styles.content}">${content}</div>
    //     </div>
    //   `;
    //   return el;
    // },
    success(content: string) {
      const el = document.createElement('div');
      el.innerHTML = `
        <div class="${styles.container} ${styles.success}">
        <img class="${styles.icon}" src="${SuccessImg}"/>
        <div class="${styles.content}">${content}</div>
        </div>
      `;
      return el;
    },
    warn(content: string) {
      const el = document.createElement('div');
      el.innerHTML = `
       <div class="${styles.container} ${styles.warn}">
       <img class="${styles.icon}" src="${WarningImg}"/>
       <div class="${styles.content}">${content}</div>
       </div>
     `;
      return el;
    },
    error(content: string) {
      const el = document.createElement('div');
      el.innerHTML = `
       <div class="${styles.container} ${styles.error}">
       <img class="${styles.icon}" src="${ErrorImg}"/>
       <div class="${styles.content}">${content}</div>
       </div>
     `;
      return el;
    },
    coming(content: string) {
      const el = document.createElement('div');
      el.innerHTML = `
        <div class="${styles.container} ${styles.success}">
        <img class="${styles.icon}" src="${ComingImg}"/>
        <div class="${styles.content}">${content}</div>
        </div>
      `;
      return el;
    },
  },
});

function success(content: string) {
  noty.success(content);
}

function warn(content: string) {
  noty.warn(content);
}

function error(content: string) {
  noty.error(content);
}

function coming(content = 'Coming Soon!') {
  noty.coming(content);
}

export const Notify = {
  success,
  warn,
  error,
  coming,
};
