import { atom, selector, selectorFamily } from 'recoil';

import { AccountMarket, Market } from '@/contracts/Lens';
import { bigGreaterThan } from '@/utils/operation';

export const connectActiveState = atom({
  key: 'connectActiveState',
  default: false,
});

export const accountAddressState = atom({
  key: 'accountAddressState',
  default: '',
});

export const chainIdState = atom({
  key: 'chainIdState',
  default: 0,
});

export interface Overview {
  totalSupply: string;
  totalBorrow: string;
}

export const overviewState = atom<Overview>({
  key: 'overviewState',
  default: { totalSupply: '', totalBorrow: '' },
});

export const marketsState = atom<Market[]>({
  key: 'marketsState',
  default: [],
});

export const modalState = atom({
  key: 'modalState',
  default: false,
});

export const modal2State = atom({
  key: 'modal2State',
  default: false,
});

export const actionTypeState = atom({
  key: 'actionTypeState',
  default: '',
});

export const actionType2State = atom({
  key: 'actionType2State',
  default: '',
});

export const marketState = selectorFamily<Market | undefined, string>({
  key: 'marketState',
  get:
    (marketAddress) =>
    ({ get }) => {
      const markets = get(marketsState);
      return markets.find((item) => item.marketAddress === marketAddress);
    },
});

export const accountMarketsState = atom<AccountMarket[]>({
  key: 'accountMarketsState',
  default: [],
});

export const accountMarketState = selectorFamily<AccountMarket | undefined, string>({
  key: 'accountMarketState',
  get:
    (marketAddress) =>
    ({ get }) => {
      const markets = get(accountMarketsState);
      return markets.find((item) => item.market?.marketAddress === marketAddress);
    },
});

export const accountSupplyMarketsState = selector<AccountMarket[]>({
  key: 'accountSupplyMarketsState',
  get: ({ get }) => {
    const markets = get(accountMarketsState);
    return markets.filter((item) => bigGreaterThan(item.userSupply, '0')) ?? [];
  },
});

export const accountBorrowMarketsState = selector<AccountMarket[]>({
  key: 'accountBorrowMarketsState',
  get: ({ get }) => {
    const markets = get(accountMarketsState);
    return markets.filter((item) => bigGreaterThan(item.userBorrow, '0')) ?? [];
  },
});

export interface AccountOverview {
  userTotalSupply: string;
  userTotalSupplyInMarket: string;
  userTotalBorrow: string;
  borrowCap: string;
  liquidity: string;
}

export const accountOverviewState = atom<AccountOverview>({
  key: 'accountOverviewState',
  default: { userTotalSupply: '', userTotalSupplyInMarket: '', userTotalBorrow: '', borrowCap: '', liquidity: '' },
});

export interface PendingClaim {
  farmIPending: string;
  farmIIPending: string;
  pendingDistributionI: string;
  pendingDistributionII: string;
}

export const accountPendingClaimState = atom<PendingClaim>({
  key: 'accountPendingClaimState',
  default: { farmIPending: '', farmIIPending: '', pendingDistributionI: '', pendingDistributionII: '' },
});
