import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Popover } from '@headlessui/react';

import { ButtonOutlineLarge, ButtonOutlineSmall, ButtonPrimaryLarge } from '@/components/Button';
import CoinIcon from '@/components/CoinIcon';
import LTVProgress from '@/components/LTVProgress';
import Input from '@/components/Input';

import { AccountMarket, Market } from '@/contracts/Lens';
import { formatCurrency4Loans, formatNumber4Loans, formatPercentages4Loans } from '@/utils/format';
import { ellipsizeText } from '@/utils/input';
import { bigDiv, bigGreaterThan, bigMul, bigNumber, bigPlus, bigSub, isNumeric } from '@/utils/operation';
import { accountMarketState, AccountOverview, accountOverviewState, chainIdState } from '@/state/Wallet';

import { AccountCallResponse, useBorrow, useDeposit, useRepay, useWithdraw } from '@/hooks/useAccount';

import { hasString } from '@/utils/helper';
import { Notify } from '@/components/Notify';
import { getExplorer, isEther } from '@/config/tools';

import CustomDialog from '@/components/Dialog/CustomDialog';
import { Pop, TIPS } from '@/components/Pop';

import bg2 from '@/assets/about/bg2.webp';
import ImgBack from '@/assets/common/backRed.png';

interface ItemProps {
  title: string;
  value: string;
  detail?: string;
}

const Item = (props: { data: ItemProps }) => {
  const { data } = props;
  return (
    <div className="mt-1 flex items-center justify-between">
      <div className="flex items-center">
        <div className="text-sm font-normal text-white/50">{data.title}</div>
        {hasString(data.detail) ? <Pop data={data.detail} /> : null}
      </div>
      <div className="text-base font-bold text-white">{data.value}</div>
    </div>
  );
};

const ItemSmall = (props: { data: ItemProps }) => {
  const { data } = props;
  return (
    <div className="mt-1 flex items-center justify-between">
      <div className="flex items-center">
        <div className="text-xs font-normal text-white/50">{data.title}</div>
        {hasString(data.detail) ? <Pop data={data.detail} /> : null}
      </div>
      <div className="text-xs font-bold text-white/60">{data.value}</div>
    </div>
  );
};

const Info = (props: { data: Market }) => {
  const { data } = props;

  const utilization = bigDiv(data.totalBorrows, bigPlus(data.totalBorrows, bigSub(data.totalCash, data.totalReserves)));

  const item1 = [
    { title: 'Total Supply', value: formatNumber4Loans(data.totalSupply, { places: 2, thousands: true }) },
    { title: 'Total Borrow', value: formatNumber4Loans(data.totalBorrows, { places: 2, thousands: true }) },
    { title: 'Supply APY', value: formatPercentages4Loans(data.supplyApy) },
    { title: 'Borrow APY', value: formatPercentages4Loans(data.borrowApy) },
    { title: 'Utilization', value: formatPercentages4Loans(utilization ?? '') },
  ];

  const item2 = [
    { title: 'Supply APY', value: formatPercentages4Loans(data.supplyMineApy) },
    { title: 'Borrow APY', value: formatPercentages4Loans(data.borrowMineApy) },
  ];

  const chainId = useRecoilValue(chainIdState);

  return (
    <div className="mr-[4.5rem] hidden lg:block">
      <div className="flex items-center">
        <CoinIcon className="h-14 w-14" name={data.underlyingSymbol} />
        <div className="ml-4 flex flex-col justify-center">
          <div className="text-[1.375rem] font-bold text-white">
            {data.underlyingSymbol}
            <span className="ml-2 text-sm font-normal text-white/50">{formatCurrency4Loans(data.price)}</span>
          </div>
          {isEther(data.underlyingAddress) ? null : (
            <a
              className="mt-0.5 text-left text-sm font-normal text-[#0067ff]"
              href={`${getExplorer(chainId)}/token/${data.underlyingAddress}`}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              {ellipsizeText(data.underlyingAddress, 14)}
            </a>
          )}
        </div>
      </div>
      <div className="mt-4 w-80 rounded-lg bg-black p-4">
        {item1.map((item, index) => (
          <Item data={item} key={index} />
        ))}
        {/* <div className="mt-2.5 h-px w-full bg-white/10" />
        <div className="mt-2 flex items-center">
          <CoinIcon className="h-4 w-4" name="CLND" />
          <div className="ml-1 text-base font-bold text-white">CLND Rewards</div>
        </div>
        {item2.map((item, index) => (
          <Item data={item} key={index} />
        ))} */}
      </div>
    </div>
  );
};

type OperatorProps = AccountMarketWithType &
  AccountOverview &
  AccountCallResponse & {
    title: string;
    lendAmountDes: string;
    lendAmount: string;
    availableAmountDes: string;
    availableAmountDetail?: string;
    collateralFactor: string;
    ltv: string;
    lendAPYDes: string;
    lendAPY: string;
    distributeAPY: string;
    totalAPY: string;
  };

const Operator = (props: { data: OperatorProps }) => {
  const { data } = props;

  const suffix = <ButtonOutlineSmall onClick={data.onMax}>Max</ButtonOutlineSmall>;

  const [open, setOpen] = useState(false);

  const submit = async () => {
    const isApproved = data.isApproved;
    await data.onSubmit({
      onSuccess: (res) => {
        Notify.success(`${data.title === 'Supply' ? (!isApproved ? 'Approve' : 'Supply') : data.title} Success`);
        if (isApproved) {
          data.setAmount('');
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        Notify.error(err.message);
      },
    });
  };

  const handleSubmit = () => {
    if (['Withdraw', 'Borrow'].includes(data.title)) {
      if (bigGreaterThan(data.amount, data.market?.totalCash)) {
        Notify.warn('Insufficient market liquidity');
        return;
      }
      if (bigGreaterThan(data.amount, data.avilable)) {
        setOpen(true);
        return;
      }
    }
    submit();
  };

  const handleContinue = () => {
    setOpen(false);
    submit();
  };

  const list = ['Supply', 'Withdraw', 'Borrow', 'Repay'];

  const collateralFactor = data.collateralFactor ?? '';
  const maxFactor = bigMul(collateralFactor, '0.9') ?? '';
  const borrowLimit = isNumeric(data.ltv) ? data.ltv : '0';

  const item1 = [
    {
      title: data.lendAmountDes,
      value: `${formatNumber4Loans(data.lendAmount, { places: 4, thousands: true })} ${data.market?.underlyingSymbol}`,
    },
    {
      title: data.availableAmountDes,
      value: `${formatNumber4Loans(data.avilable, { places: 4, thousands: true })} ${data.market?.underlyingSymbol}`,
      detail: data.availableAmountDetail,
    },
  ];

  const item2 = [
    {
      title: 'Current LTV',
      value: formatPercentages4Loans(borrowLimit),
    },
    {
      title: 'Max LTV',
      value: formatPercentages4Loans(maxFactor),
      detail: TIPS.MaxLTV,
    },
    { title: 'Liquidation LTV', value: formatPercentages4Loans(collateralFactor) },
  ];

  const item3 = [
    { title: data.lendAPYDes, value: formatPercentages4Loans(data.lendAPY) },
    // { title: 'CLND Reward APY', value: formatPercentages4Loans(data.distributeAPY) },
    // { title: 'Total APY', value: formatPercentages4Loans(data.totalAPY) },
  ];

  const ltv = (
    <div className="mt-4 mb-4 rounded-lg bg-black px-8 py-4">
      <div className="flex items-center">
        <div className="text-normal text-left text-base text-white/60">Loan to value</div>
        <Pop data={TIPS.LTV} />
      </div>
      <LTVProgress
        className="mt-6 mb-6"
        liquidation={bigNumber(collateralFactor)?.toNumber()}
        max={bigNumber(maxFactor)?.toNumber()}
        current={bigNumber(borrowLimit)?.toNumber()}
      />
      {item2.map((item, index) => (
        <ItemSmall data={item} key={index} />
      ))}
    </div>
  );

  const bottom = (
    <div className="mt-4 flex items-center justify-center text-sm font-medium text-[#e921c3]">
      {list
        .filter((item) => item !== data.title)
        .map((item, index) => (
          <button
            className="mr-6 border-b border-[#e921c3] last:mr-0 hover:border-[#ef64d5] hover:text-[#ef64d5]"
            onClick={() => data.setOperatorType(item)}
            key={index}
          >
            {item}
          </button>
        ))}
    </div>
  );

  return (
    <div className="flex-1">
      <div className="text-left text-[1.375rem] font-bold text-white">{data.title}</div>
      <Input
        className="mt-6"
        value={data.amount}
        precision={data.market?.underlyingDecimals ?? 18}
        onChange={data.setAmount}
        placeholder="Input Amount"
        suffix={suffix}
        prefix="Amount"
      />
      <div className="px-4">
        {item1.map((item, index) => (
          <Item data={item} key={index} />
        ))}
        {data.title === 'Supply' ? (
          <Item
            data={{
              title: 'Collateral Factor',
              value: formatPercentages4Loans(data.market?.collateralFactorMantissa ?? ''),
              detail: TIPS.CollateralFactor,
            }}
          />
        ) : null}
        {ltv}
        {item3.map((item, index) => (
          <Item data={item} key={index} />
        ))}
      </div>
      <ButtonPrimaryLarge
        className="mt-4 w-full"
        loading={data.loading}
        disabled={data.disabled}
        onClick={handleSubmit}
      >
        {data.title === 'Supply' ? (hasString(data.amount) && !data.isApproved ? 'Approve' : 'Supply') : data.title}
      </ButtonPrimaryLarge>
      {bottom}
      <CustomDialog open={open} onClose={() => setOpen(false)}>
        <div>
          <p className="text-center text-lg font-bold text-white">
            Doing so will increase the risk of liquidation, are you sure you want to do this?
          </p>
          <div className="mt-6 flex items-center">
            <ButtonOutlineLarge className="flex-1" onClick={() => setOpen(false)}>
              Cancel
            </ButtonOutlineLarge>
            <ButtonPrimaryLarge className="ml-4 flex-1" onClick={handleContinue}>
              Continue
            </ButtonPrimaryLarge>
          </div>
        </div>
      </CustomDialog>
    </div>
  );
};

type AccountMarketWithType = AccountMarket & { setOperatorType: React.Dispatch<React.SetStateAction<string>> };

const Supply = (props: { data: AccountMarketWithType }) => {
  const { data } = props;
  const op = useDeposit(data);
  const overview = useRecoilValue(accountOverviewState);
  let collateralFactor = bigDiv(overview.borrowCap, overview.userTotalSupplyInMarket);
  let ltv = bigDiv(overview.userTotalBorrow, overview.userTotalSupplyInMarket);
  if (data.isAssetIn) {
    const value = bigMul(data.market?.collateralFactorMantissa, bigMul(op.amount, data.market?.price)) ?? 0;
    collateralFactor = bigDiv(bigPlus(overview.borrowCap, value), bigPlus(overview.userTotalSupplyInMarket, value));
    ltv = bigDiv(overview.userTotalBorrow, bigPlus(value, overview.userTotalSupplyInMarket));
  }

  return (
    <Operator
      data={{
        ...op,
        ...data,
        ...overview,
        title: 'Supply',
        lendAmountDes: 'Supplied Amount',
        lendAmount: data.userSupply,
        availableAmountDes: 'Wallet Balance',
        collateralFactor: collateralFactor ?? '',
        ltv: ltv ?? '0',
        lendAPYDes: 'Supply APY',
        lendAPY: data.market?.supplyApy ?? '',
        distributeAPY: data.market?.supplyMineApy ?? '',
        totalAPY: bigPlus(data.market?.supplyApy, data.market?.supplyMineApy) ?? '',
      }}
    />
  );
};

const Withdraw = (props: { data: AccountMarketWithType }) => {
  const { data } = props;
  const op = useWithdraw(data);
  const overview = useRecoilValue(accountOverviewState);
  let collateralFactor = bigDiv(overview.borrowCap, overview.userTotalSupplyInMarket);
  let ltv = bigDiv(overview.userTotalBorrow, overview.userTotalSupplyInMarket);
  if (data.isAssetIn) {
    const value = bigMul(data.market?.collateralFactorMantissa, bigMul(op.amount, data.market?.price)) ?? 0;
    collateralFactor = bigDiv(bigSub(overview.borrowCap, value), bigSub(overview.userTotalSupplyInMarket, value));
    ltv = bigDiv(overview.userTotalBorrow, bigSub(overview.userTotalSupplyInMarket, value));
  }

  return (
    <Operator
      data={{
        ...op,
        ...data,
        ...overview,
        title: 'Withdraw',
        lendAmountDes: 'Supplied Amount',
        lendAmount: data.userSupply,
        availableAmountDes: 'Max Withdrawal',
        availableAmountDetail: TIPS.MaxWithdrawal,
        collateralFactor: collateralFactor ?? '',
        ltv: ltv ?? '0',
        lendAPYDes: 'Supply APY',
        lendAPY: data.market?.supplyApy ?? '',
        distributeAPY: data.market?.supplyMineApy ?? '',
        totalAPY: bigPlus(data.market?.supplyApy, data.market?.supplyMineApy) ?? '',
      }}
    />
  );
};

const Borrow = (props: { data: AccountMarketWithType }) => {
  const { data } = props;
  const op = useBorrow(data);
  const overview = useRecoilValue(accountOverviewState);
  const collateralFactor = bigDiv(overview.borrowCap, overview.userTotalSupplyInMarket);
  let ltv = bigDiv(overview.userTotalBorrow, overview.userTotalSupplyInMarket);
  const value = bigMul(op.amount, data.market?.price) ?? 0;
  ltv = bigDiv(bigPlus(overview.userTotalBorrow, value), overview.userTotalSupplyInMarket);

  return (
    <Operator
      data={{
        ...op,
        ...data,
        ...overview,
        title: 'Borrow',
        lendAmountDes: 'Borrowed Amount',
        lendAmount: data.userBorrow,
        availableAmountDes: 'Max Borrow',
        availableAmountDetail: TIPS.MaxBorrow,
        collateralFactor: collateralFactor ?? '',
        ltv: ltv ?? '0',
        lendAPYDes: 'Borrow APY',
        lendAPY: data.market?.borrowApy ?? '',
        distributeAPY: data.market?.borrowMineApy ?? '',
        totalAPY: bigSub(data.market?.borrowApy, data.market?.borrowMineApy) ?? '',
      }}
    />
  );
};

const Repay = (props: { data: AccountMarketWithType }) => {
  const { data } = props;
  const op = useRepay(data);
  const overview = useRecoilValue(accountOverviewState);
  const collateralFactor = bigDiv(overview.borrowCap, overview.userTotalSupplyInMarket);
  let ltv = bigDiv(overview.userTotalBorrow, overview.userTotalSupplyInMarket);
  const value = bigMul(op.amount, data.market?.price) ?? 0;
  ltv = bigDiv(bigSub(overview.userTotalBorrow, value), overview.userTotalSupplyInMarket);

  return (
    <Operator
      data={{
        ...op,
        ...data,
        ...overview,
        title: 'Repay',
        lendAmountDes: 'Borrowed Amount',
        lendAmount: data.userBorrow,
        availableAmountDes: 'Wallet Balance',
        collateralFactor: collateralFactor ?? '',
        ltv: ltv ?? '0',
        lendAPYDes: 'Borrow APY',
        lendAPY: data.market?.borrowApy ?? '',
        distributeAPY: data.market?.borrowMineApy ?? '',
        totalAPY: bigSub(data.market?.borrowApy, data.market?.borrowMineApy) ?? '',
      }}
    />
  );
};

function Lend() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { marketAddress, type } = state as { marketAddress: string; type: string };

  const [operatorType, setOperatorType] = useState(type);

  if (marketAddress == null) {
    <Link to={'/markets'} />;
  }

  const accountMarket = useRecoilValue(accountMarketState(marketAddress));
  const market = accountMarket?.market;

  if (market == null || accountMarket == null) {
    return <div>Loading</div>;
  }

  let operator;
  switch (operatorType) {
    case 'Supply':
      operator = <Supply data={{ ...accountMarket, setOperatorType }} />;
      break;
    case 'Withdraw':
      operator = <Withdraw data={{ ...accountMarket, setOperatorType }} />;
      break;
    case 'Borrow':
      operator = <Borrow data={{ ...accountMarket, setOperatorType }} />;
      break;
    case 'Repay':
      operator = <Repay data={{ ...accountMarket, setOperatorType }} />;
      break;
    default:
      operator = <Supply data={{ ...accountMarket, setOperatorType }} />;
      break;
  }

  return (
    <div className="relative mx-auto flex flex-col items-center bg-transparent pt-px">
      <img className="fixed h-[53.5rem] object-fill md:w-full" src={bg2} alt="" />
      <p className="mt-8 bg-gradient-to-r from-[#e921c3] to-[#0067ff] bg-clip-text text-3xl font-bold text-transparent">
        {operatorType}
      </p>
      <div className="relative mx-auto mt-2 w-full max-w-[54.5rem] px-4 md:px-16 lg:px-0">
        <div className="mt-2 flex cursor-pointer items-center" onClick={() => navigate(-1)}>
          <img className="h-6 w-6" src={ImgBack} alt="back" />
          <span className="ml-1 text-base font-bold text-[#e921c3]">Back</span>
        </div>
        <div className="mt-2 rounded-lg bg-gradient-to-b from-[#e921c3] to-[#0067ff] p-px">
          <div className="flex justify-between rounded-lg bg-[#131415] px-6 pt-6 pb-4 text-center">
            <Info data={market} />
            {operator}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Lend;
