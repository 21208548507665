import { BigNumber, Contract, ContractTransaction, Signer, utils, constants } from 'ethers';

import { TokenContract } from './TokenContract';
import { mapEstimateGas, EstimateGas } from './tool';

import { getContracts } from '@/config/contracts';

import CERC20_ABI_json from './abis/CERC20.json';
import CEther_ABI_json from './abis/CEther.json';
import Maxmillion_ABI_json from './abis/Maximillion.json';

export class CTokenContract {
  private contract: Contract;
  private isEther: boolean;
  private estimateGas: EstimateGas;

  constructor(address: string, isEther: boolean, signer?: Signer) {
    this.contract = new Contract(address, isEther ? CEther_ABI_json : CERC20_ABI_json, signer);
    this.isEther = isEther;
    this.estimateGas = mapEstimateGas(this.contract.estimateGas);
  }

  async mint(amount: string): Promise<ContractTransaction> {
    const [isEther, contract, estimateGas] = [this.isEther, this.contract, this.estimateGas];

    const value = await this.parseAmount(amount);
    const gasLimit = await (isEther ? estimateGas.mint() : estimateGas.mint(value));
    const overrides = isEther ? { value, gasLimit } : { gasLimit };
    return isEther ? contract.mint(overrides) : contract.mint(value, overrides);
  }

  async redeemAll(): Promise<ContractTransaction> {
    const address = await this.contract.signer.getAddress();
    const cTokenBalance = await this.contract.balanceOf(address);
    const gasLimit = await this.estimateGas.redeem(cTokenBalance);
    const overrides = { gasLimit };
    return this.contract.redeem(cTokenBalance, overrides);
  }

  async redeem(amount: string): Promise<ContractTransaction> {
    const value = await this.parseAmount(amount);
    const gasLimit = await this.estimateGas.redeemUnderlying(value);
    const overrides = { gasLimit };
    return this.contract.redeemUnderlying(value, overrides);
  }

  async borrow(amount: string): Promise<ContractTransaction> {
    const value = await this.parseAmount(amount);
    const gasLimit = await this.estimateGas.borrow(value);
    const overrides = { gasLimit };
    return this.contract.borrow(value, overrides);
  }

  async repayBorrow(amount: string, isAll: boolean): Promise<ContractTransaction> {
    const [isEther, contract, estimateGas] = [this.isEther, this.contract, this.estimateGas];

    const value = await this.parseAmount(amount);
    const gasLimit = await (isEther ? estimateGas.repayBorrow() : estimateGas.repayBorrow(value));
    const overrides = isEther ? { value, gasLimit } : { gasLimit };

    return isEther
      ? isAll
        ? this.repayBorrowWithMaximillion(value)
        : contract.repayBorrow(overrides)
      : contract.repayBorrow(isAll ? constants.MaxUint256 : value, overrides);
  }

  async repayBorrowWithMaximillion(amount: BigNumber): Promise<ContractTransaction> {
    const Maximillion = new Contract(getContracts().maximillion, Maxmillion_ABI_json, this.contract.signer);

    const overrides = { value: amount.mul(10005).div(10000), gasLimit: BigNumber.from(0) };
    const address = await this.contract.signer.getAddress();
    overrides.gasLimit = await Maximillion.estimateGas.repayBehalfExplicit(address, this.contract.address, overrides);
    return Maximillion.repayBehalfExplicit(address, this.contract.address, overrides);
  }

  async underlyingToken() {
    let underlying = '';
    if (!this.isEther) {
      underlying = await this.contract.underlying();
    }
    return new TokenContract(underlying, this.isEther, this.contract.signer);
  }

  async underlyingBalance() {
    const Token = await this.underlyingToken();
    return Token.balance();
  }

  async underlyingAllowance() {
    const Token = await this.underlyingToken();
    return Token.allowance(this.contract.address);
  }

  async underlyingApprove(amount?: string): Promise<ContractTransaction> {
    const Token = await this.underlyingToken();
    return Token.approve(this.contract.address, amount);
  }

  async underlyingDecimals() {
    const Token = await this.underlyingToken();
    return Token.decimals();
  }

  async parseAmount(amount: string) {
    return this.isEther ? utils.parseEther(amount) : utils.parseUnits(amount, await this.underlyingDecimals());
  }
}
