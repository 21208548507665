import { atom } from 'recoil';
export interface UserInfoState {
  amount: string;
  rewards: string;
  released: string;
}

export const userInfoState = atom<UserInfoState>({
  key: 'userInfoState',
  default: { amount: '', rewards: '', released: '' },
});

export const allowanceState = atom({
  key: 'allowanceState',
  default: '',
});

export const balanceState = atom({
  key: 'balanceState',
  default: '',
});

export const farmslptokenbanlanceState = atom({
  key: 'farmslptokenbanlanceState',
  default: '',
});

export const rewardPerSecondState = atom({
  key: 'rewardPerSecondState',
  default: '',
});

export const pendingRewardsState = atom({
  key: 'pendingRewardsState',
  default: '',
});

export const disableClaimState = atom({
  key: 'disableClaimState',
  default: false,
});

export interface UserInfo2State {
  amount: string;
  rewards: string;
  released: string;
}

export const userInfo2State = atom<UserInfo2State>({
  key: 'userInfo2State',
  default: { amount: '', rewards: '', released: '' },
});

export const allowance2State = atom({
  key: 'allowance2State',
  default: '',
});

export const balance2State = atom({
  key: 'balance2State',
  default: '',
});

export const farmslptokenbanlance2State = atom({
  key: 'farmslptokenbanlance2State',
  default: '',
});

export const rewardPerSecond2State = atom({
  key: 'rewardPerSecond2State',
  default: '',
});

export const pendingRewards2State = atom({
  key: 'pendingRewards2State',
  default: '',
});

export const disableClaim2State = atom({
  key: 'disableClaim2State',
  default: false,
});

export const endTimeState = atom({
  key: 'endTime',
  default: 0,
});
