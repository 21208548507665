import { hasString } from './helper';

export function getWalletName(defaultValue?: string) {
  const value = localStorage.getItem('wallet_name');
  return hasString(value) ? value : defaultValue;
}

export function setWalletName(value: string) {
  return localStorage.setItem('wallet_name', value);
}

export function removeWalletName() {
  return localStorage.removeItem('wallet_name');
}

export function removeWalletConnect() {
  return localStorage.removeItem('walletconnect');
}
